import { GeneralService } from 'src/app/services/general.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, observable, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit {
  @ViewChild('input', { static: false }) input: ElementRef;
  isARequest;
  routerNam: string;
  @Input() set requestWaste(value) {
    if (value) {
      this.isARequest = value;
      this.setInputSize();
    }
  }
  @Input() anotherInput;
  @Output() filters = new EventEmitter();
  currentLang = this.translate.currentLang;
  searchInputSize = 1;
  text = '';
  query = '';

  status;
  @Input() set statusList(value) {
    this.status = value;
  }

  faSearch = faSearch;
  faTimes = faTimes;
  activeSearch = false;

  constructor(private translate: TranslateService,
    private router: Router,
    private generalService: GeneralService) {
    this.routerNam = this.router.url;
    generalService.langEmitter.subscribe((rep: any) => {
      if (rep) {
        this.filterSearch();
      }
    })
  }
  ngAfterViewInit(): void {
    if (this.input) {
      fromEvent(this.input.nativeElement, 'input')
        .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(1000))
        .pipe(distinctUntilChanged())
        .subscribe((data) => this.onSearchKey(data));
    }
  }

  ngOnInit() {

    this.setInputSize();
    this.changeLangDetict();
    // this.filterSearch()
  }

  get getStatus() {
    return this.status;
  }

  setInputSize() {
    if (this.isARequest) {
      this.searchInputSize =
        this.translate.instant('Header.RequestWaste').length + 1;
    } else {
      this.searchInputSize = this.translate.instant('Search.BuyWaste').length;
    }
  }

  changeLangDetict() {
    this.translate.onLangChange.subscribe((lang) => {
      this.setInputSize();
    });
  }

  onSearchKey(value) {
    if (value.replace(/\s/g, '').length) {
      this.searchInputSize = value.length + 1;
      this.text = value;
      this.filterSearch();
    } else {
      this.text = '';
      this.setInputSize();
      this.filterSearch();
    }
  }

  clearFilters(e) {
    this.text = '';
    this.filterSearch();
  }

  filterSearch(query?) {
    const q = { ...query, is_request: 0, q: this.text };
    this.filters.emit(q);
  }
}
