import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-waste',
  templateUrl: './request-waste.component.html',
  styleUrls: ['./request-waste.component.scss'],
})
export class RequestWasteComponent implements OnInit {
  filters = '';

  constructor() {}

  ngOnInit() {}

  applyFilters(filters) {
    this.filters = filters;
  }
}
