import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';
import { LookupsService } from 'src/app/services/lookups.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { OffersService } from 'src/app/services/offers.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss'],
})
export class NewOfferComponent implements OnInit {
  @ViewChild('inputSearchAutoComplete', { static: true }) inputSearchAutoComplete: ElementRef;
  filterAutoComplete = {
    q: '',
    p: 1
  }
  today: Date;

  isARequest;
  Offer_id: any;
  offer_detalis: any;
  offerIdDuplicate: any;
  lat: number;
  long: number;
  autocompleteLists = [];
  autocompleteListsPage: any;
  autocompleteListsPageSize: any;
  autocompleteTotalPages: any;
  lang: string;
  @Input() set requestWaste(value) {
    if (value) {
      this.isARequest = value;
    }
  }
  faSpinner = faSpinner;
  step = 1;
  newOfferForm: FormGroup;
  step1Submitted = false;
  step2Submitted = false;
  submitted = false;
  loading = false;
  validComboDrag: boolean
  errorMsg = '';

  mainImagePreview;
  otherImagesPreview = [];
  otherImagesList = [];
  uploadMainImage = false;
  uploadotherImages = false;
  uploadSheet = false;
  dragedFilesForOtherImages: File[] = [];
  dragedFilesForMainImage: File[] = [];
  dragedFilesForSheets: File[] = [];

  sectors = [];
  subSectors = [];
  units = [];
  currencies = [];
  supplyFrequencies = [];
  cities = [];
  areas = [];

  searchTerm = '';
  selectedCategory = '';
  selectedSubCategory = '';
  selectedUnit = '';
  selectedCurrency = '';
  selectedVat = '';
  selectedSupplyFrequency = '';
  selectedCity = '';
  selectedArea = '';
  selectedSheets = [];
  selectedSheetsUrls = [];
  openAutoComplete: boolean = false;
  imagesLoading: boolean = false;
  yesOrNo = [
    {
      name: 'yes',
      value: true,
    },
    {
      name: 'no',
      value: false,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private lookupsService: LookupsService,
    private offersService: OffersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifications: NotificationsService

  ) { }



  ngOnInit() {
    this.today = new Date();
    this.today.setDate(this.today.getDate() + 1);
    this.lang = localStorage.getItem('lang')
    this.getLookups();
    this.initForm()
    this.activatedRoute.params.subscribe((params) => {
      console.log(params)
      if (params.offerId) {
        this.Offer_id = params.offerId
        this.offersService.getOffer(params.offerId)
          .subscribe((rep: any) => {
            this.offer_detalis = rep.data;
            // rep.data.expiry_date = new Date(rep.data.expiry_date)
            if (rep.data.expiry_date) rep.data.expiry_date = new Date(rep.data.expiry_date)

            this.newOfferForm.patchValue(rep.data)
            this.selectedUnit = rep.data.unit.name;
            this.mainImagePreview = rep.data.image
            this.otherImagesPreview = rep.data.other_iamges ? rep.data.other_iamges : [];
            this.lat = rep.data.latitude
            this.long = rep.data.longitude
            // this.offer_detalis.latitude = parseFloat(this.offer_detalis.latitude)
            // this.offer_detalis.longitude = parseFloat(this.offer_detalis.longitude)
            setTimeout(() => {
              const indexSector = this.sectors.findIndex(item => item.id == this.offer_detalis.category_id)
              if (indexSector !== -1) {
                this.offer_detalis.category.children = this.sectors[indexSector].children
              }
              const indexCity = this.cities.findIndex(item => item.id == this.offer_detalis.city_id)
              if (indexSector !== -1) {
                this.offer_detalis.city.areas = this.cities[indexCity].areas
              }
              this.selectCategory(this.offer_detalis.category);
              this.selectSubCategory(this.offer_detalis.subCategory)
              this.selectCurrency(this.offer_detalis.currency)
              this.selectSupplyFrequency(this.offer_detalis.area)
              this.selectCity(this.offer_detalis.city)
              this.selectArea(this.offer_detalis.area)
              if (this.offer_detalis.includes_vat) {
                this.selectVatValue(this.yesOrNo[0])
              } else {
                this.selectVatValue(this.yesOrNo[1])
              }

            }, 100);

            if (rep.data.category.is_other) {
              this.newOfferForm.get('category_other').setValue(rep.data.category_other_text)
            }
            if (rep.data.data_sheets.length) {
              this.selectedSheets = rep.data.data_sheets
            }
            // if(rep.data.subCategory.is_other){
            //   this.newOfferForm.get('subcategory_other').setValue(rep.data.subcategory_other_text)
            // }
            // // if(rep.data.city.is_other){
            // //   this.newOfferForm.get('category_other').setValue(rep.data.city_other_text)
            // // }
            // if(rep.data.area.is_other){
            //   this.newOfferForm.get('area_other_text').setValue(rep.data.area_other_text)
            // }
          })
      } else if (params.offerIdDuplicate) {
        this.offerIdDuplicate = params.offerIdDuplicate
        this.offersService.getOffer(params.offerIdDuplicate)
          .subscribe((rep: any) => {
            this.offer_detalis = rep.data.offer;
            if (rep.data.expiry_date) rep.data.expiry_date = new Date(rep.data.expiry_date)
            this.newOfferForm.patchValue(rep.data.offer)
            this.selectedUnit = rep.data.unit.name;
            this.mainImagePreview = rep.data.image
            this.otherImagesPreview = rep.data.other_iamges;
            this.lat = rep.data.latitude
            this.long = rep.data.longitude
            if (rep.data.other_iamges) {
              this.newOfferForm.get('other_images').setValue(rep.data.other_iamges)
            }
            // this.offer_detalis.latitude = parseFloat(this.offer_detalis.latitude)
            // this.offer_detalis.longitude = parseFloat(this.offer_detalis.longitude)
            setTimeout(() => {
              const indexSector = this.sectors.findIndex(item => item.id == this.offer_detalis.category_id)
              if (indexSector !== -1) {
                this.offer_detalis.category.children = this.sectors[indexSector].children
              }
              const indexCity = this.cities.findIndex(item => item.id == this.offer_detalis.city_id)
              if (indexSector !== -1) {
                this.offer_detalis.city.areas = this.cities[indexCity].areas
              }
              this.selectCategory(this.offer_detalis.category);
              this.selectSubCategory(this.offer_detalis.subCategory)
              this.selectCurrency(this.offer_detalis.currency)
              this.selectSupplyFrequency(this.offer_detalis.area)
              this.selectCity(this.offer_detalis.city)
              this.selectArea(this.offer_detalis.area)
              if (this.offer_detalis.includes_vat) {
                this.selectVatValue(this.yesOrNo[0])
              } else {
                this.selectVatValue(this.yesOrNo[1])
              }
            }, 100);
          })
      }
    });
    this.getAutocompleteLists(this.filterAutoComplete);
    // this.searchInAutocompleteLists()
  }
  searchInAutocompleteLists() {
    fromEvent(this.inputSearchAutoComplete.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          const inputValue = event.target.value;
          // if (!isNaN(inputValue)) {
          //   console.log('number');
          // } else {
          //   console.log('string');
          // }
          return event.target.value;
        }),
        // filter((res) => res.length > 1),
        // filter((res) => res),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        // this.isSearching = true;
        this.searchAutoComplete(text).subscribe(
          (rep: any) => {
            this.autocompleteLists = rep.data;
            this.autocompleteListsPage = rep.page;
            this.autocompleteListsPageSize = rep.page_size;
            this.autocompleteTotalPages = rep.total_page
            // this.isSearching = false;
          },
          (err) => {
            console.log('error', err);
          }
        );
      });
  }
  searchAutoComplete(term) {
    if (term === '') {
      return this.offersService.getAutocompleteLists(this.filterAutoComplete);
    }
    return this.offersService.getAutocompleteLists({ p: 1, q: term });
  }
  getAutocompleteLists(filter) {
    this.offersService.getAutocompleteLists(filter)
      .subscribe((rep: any) => {
        if (rep.status === 200) {
          this.autocompleteLists = rep.data;
          this.autocompleteListsPage = rep.page;
          this.autocompleteListsPageSize = rep.page_size;
          this.autocompleteTotalPages = rep.total_pages
        }
      })
  }
  getLookups() {
    this.lookupsService.getAllLookups().subscribe((lookupsResponse: any) => {
      if (lookupsResponse.status === 200) {
        this.sectors = lookupsResponse.data.sectors;
        // .map((sec) => {
        //   sec.is_other = true;
        //   sec.children.map((sub) => {
        //     sub.is_other = true;
        //     return sub;
        //   });
        //   return sec;
        // });
        this.units = lookupsResponse.data.units;
        this.currencies = lookupsResponse.data.currencies;
        this.supplyFrequencies = lookupsResponse.data.supply_frequencies;
        this.cities = lookupsResponse.data.cities;
        // if(this.Offer_id){
        //   this.newOfferForm.get('unit_id').setValue(this.)
        // }


      }
    });
  }

  next() {
    if (this.step === 1) {
      this.step1Submitted = true;
      if (this.checkStepValidation(1) && !this.imagesLoading) {
        this.step = 2;
      }
    } else if (this.step === 2) {
      this.step2Submitted = true;
      if (this.checkStepValidation(2)) {
        this.step = 3;
      }
    }
  }

  previous() {
    this.step = this.step !== 1 ? this.step - 1 : this.step;
  }

  checkStepValidation(step) {
    if (step === 1) {
      if (
        this.newOfferForm.get('product_name_en').valid &&
        // this.newOfferForm.get('product_name_ar').valid &&
        this.newOfferForm.get('quantity').valid &&
        this.newOfferForm.get('unit_id').valid &&
        this.newOfferForm.get('category_id').valid &&
        this.newOfferForm.get('category_other').valid &&
        this.newOfferForm.get('subcategory_id').valid &&
        // this.newOfferForm.get('subcategory_other').valid &&
        // this.newOfferForm.get('expiry_date').valid &&
        this.newOfferForm.get('product_description_en').valid &&
        // this.newOfferForm.get('product_description_ar').valid &&
        this.newOfferForm.get('image').valid &&
        this.newOfferForm.get('other_images').valid
      ) {
        this.newOfferForm.get('product_name_ar').setValue(this.newOfferForm.get('product_name_en').value)
        this.newOfferForm.get('product_description_ar').setValue(this.newOfferForm.get('product_description_en').value)
        console.log(this.newOfferForm.value)
        return true;
      } else {
        return false;
      }
    } else if (step === 2) {
      if (
        this.newOfferForm.get('currency_id').valid &&
        this.newOfferForm.get('unit_price').valid &&
        this.newOfferForm.get('includes_vat').valid &&
        this.newOfferForm.get('supply_frequency_id').valid &&
        this.newOfferForm.get('city_id').valid &&
        this.newOfferForm.get('area_id').valid &&
        this.newOfferForm.get('full_address').valid &&
        // this.newOfferForm.get('area_other_text').valid &&
        // this.newOfferForm.get('latitude').valid &&
        // this.newOfferForm.get('longitude').valid &&
        this.newOfferForm.get('image').valid &&
        this.newOfferForm.get('other_images').valid
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  initForm(data?) {
    this.newOfferForm = this.formBuilder.group(
      {
        product_name_en: ['', Validators.required],
        product_name_ar: ['', Validators.required],
        quantity: ['', Validators.required],
        unit_id: ['', Validators.required],
        category_id: ['', Validators.required],
        category_other: new FormGroup({}),
        subcategory_id: [''],
        // subcategory_other: new FormGroup({}),
        expiry_date: [],
        product_description_en: [''],
        product_description_ar: [''],
        image: [''],
        other_images: [''], // array of strings
        currency_id: [''], // Mandatory in case price added
        unit_price: [''],
        includes_vat: [''], // Mandatory in case price added
        supply_frequency_id: [''],
        city_id: ['', Validators.required],
        area_id: ['', Validators.required],
        // area_other_text: [''],
        full_address: ['', Validators.required],
        latitude: [''],
        longitude: [''],
        payment_terms: [''],
        data_sheets: [''], // array of string
        comment: [''],
      },
      { validator: this.customValidation() }
    );
  }

  private customValidation(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const currency_id = group.controls.currency_id;
      const includes_vat = group.controls.includes_vat;
      if (!group.controls.unit_price.value) {
        currency_id.setErrors(null);
        includes_vat.setErrors(null);
        return;
      } else {
        if (!currency_id.value) {
          currency_id.setErrors({ required: true });
        }
        if (!includes_vat.value) {
          includes_vat.setErrors({ required: true });
        }
      }
    };
  }

  get f() {
    return this.newOfferForm.controls;
  }

  get otherCategoryForm() {
    return this.f.category_other;
  }

  get otherSubCategoryForm() {
    return this.f.subcategory_other;
  }

  addOtherCategory(category) {
    const categoryOther = this.newOfferForm.get('category_other') as FormGroup;
    categoryOther.controls = {};
    categoryOther.addControl(
      category.label,
      new FormControl('', Validators.required)
    );
  }

  // addOtherSubCategory(category) {
  //   const categoryOther = this.newOfferForm.get(
  //     'subcategory_other'
  //   ) as FormGroup;
  //   categoryOther.controls = {};
  //   categoryOther.addControl(
  //     category.label,
  //     new FormControl('', Validators.required)
  //   );
  // }

  selectCategory(category) {
    category = { ...category, label: category.name };
    this.selectedCategory = category.name;
    this.newOfferForm.patchValue({ category_id: category.id });
    this.selectedSubCategory = '';
    if (category.children) {
      this.subSectors = category.children;
    } else {
      this.newOfferForm.patchValue({ subcategory_id: '' });
    }
    if (category.is_other) {
      this.addOtherCategory(category);
    } else {
      const categoryOther = this.newOfferForm.get(
        'category_other'
      ) as FormGroup;
      categoryOther.controls = {};
    }
  }

  selectSubCategory(subCategory) {
    subCategory = { ...subCategory, label: subCategory.name };
    this.selectedSubCategory = subCategory.name;
    this.newOfferForm.patchValue({ subcategory_id: subCategory.id });
    if (subCategory.is_other) {
      // this.addOtherSubCategory(subCategory);
    } else {
      // const categoryOther = this.newOfferForm.get(
      //   'subcategory_other'
      // ) as FormGroup;
      // categoryOther.controls = {};
    }
  }

  selectUnit(unit) {
    this.selectedUnit = unit.name;
    // this.newOfferForm.patchValue({ unit_id: unit.id });
  }

  selectCurrency(currency) {
    this.selectedCurrency = currency.name;
    this.newOfferForm.patchValue({ currency_id: currency.id });
  }

  selectVatValue(val) {
    this.selectedVat = val.name;
    this.newOfferForm.patchValue({ includes_vat: val.value });
  }

  selectSupplyFrequency(supplyFrequency) {
    this.selectedSupplyFrequency = supplyFrequency.name;
    this.newOfferForm.patchValue({
      supply_frequency_id: supplyFrequency.id,
    });
  }

  selectCity(city) {
    this.selectedCity = city.name;
    this.newOfferForm.patchValue({ city_id: city.id });
    this.areas = city.areas;
  }

  selectArea(area) {
    this.selectedArea = area.name;
    this.newOfferForm.patchValue({ area_id: area.id });
  }

  selectSheet(files) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        const file = files[key];
        this.uploadAndPatch('sheet', 'uploadSheet', file);
      }
    }
    this.dragedFilesForSheets = [];
  }

  selectImage(place, files) {
    if (files.length === 0) {
      return;
    }
    if (place === 'main') {

      if (this.checkMimeType(files[0])) {
        return;
      }
      this.previewImage(place, 'mainImagePreview', files[0]);
      this.uploadAndPatch(place, 'uploadMainImage', files[0]);
      this.dragedFilesForMainImage = [];
    } else if (place === 'other') {
      if (files.length > 1) {
        for (const key in files) {
          if (Object.prototype.hasOwnProperty.call(files, key)) {
            const file = files[key];
            if (this.checkMimeType(file)) {
              return;
            }
            this.previewImage(place, 'otherImagesPreview', file);
            this.uploadAndPatch(place, 'uploadotherImages', file);
          }
        }
      } else {
        if (this.checkMimeType(files[0])) {
          return;
        }
        this.previewImage(place, 'otherImagesPreview', files[0]);
        this.uploadAndPatch(place, 'uploadotherImages', files[0]);
      }
      this.dragedFilesForOtherImages = [];
    }
  }

  previewImage(place, previewSelector, file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (place === 'main') {
        this[previewSelector] = reader.result;
      } else if (place === 'other') {
        this[previewSelector].push(reader.result);
      }
    };
  }

  checkMimeType(file) {
    const mimeType = file.type;
    return mimeType.match(/image\/*/) == null;
  }

  uploadAndPatch(place, loading, file) {
    this.imagesLoading = true;
    this[loading] = true;
    const formData = new FormData();
    formData.append('file[]', file);
    this.generalService.upload(formData).subscribe((uploadRes: any) => {
      if (uploadRes.status === 200) {
        const url = uploadRes.data.url[0];
        if (place === 'main') {
          this.newOfferForm.patchValue({
            image: uploadRes.data.url ? uploadRes.data.url : [],
          });

        } else if (place === 'other') {
          this.otherImagesList.push(url);
          this.newOfferForm.patchValue({
            other_images: this.otherImagesList,
          });

        } else if (place === 'sheet') {
          this.selectedSheets.push(file.name);
          this.selectedSheetsUrls.push(url);
          this.newOfferForm.patchValue({
            data_sheets: this.selectedSheetsUrls,
          });
        }
      }
      this[loading] = false;
      this.imagesLoading = false;
    });
  }

  updateLangLat(e) {
    this.newOfferForm.patchValue({ latitude: e.latitude });
    this.newOfferForm.patchValue({ longitude: e.longitude });
    this.lat = e.latitude
    this.long = e.longitude
  }

  fixDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  dragOther() {
    this.selectImage('other', this.dragedFilesForOtherImages);
  }

  dragMain() {
    if (this.dragedFilesForMainImage.length === 1) {
      this.selectImage('main', this.dragedFilesForMainImage);
    } else {
      this.dragedFilesForMainImage = [];
    }
  }

  dragSheets() {
    this.selectSheet(this.dragedFilesForSheets);
  }

  submit() {

    this.submitted = true;
    this.errorMsg = '';
    if (this.newOfferForm.valid && !this.imagesLoading) {
      this.loading = true;
      if (this.newOfferForm.get('expiry_date').value) {
        this.newOfferForm.value.expiry_date = this.fixDate(
          this.newOfferForm.value.expiry_date
        );
      } else {
        this.newOfferForm.get('expiry_date').setValue(null)
      }
      const value = this.newOfferForm.value;
      // if (Object.keys(value.category_other).length) {
      //   value.category_other_text = Object.values(value.category_other)[0];
      // }
      // if (Object.keys(value.subcategory_other).length) {
      //   value.subcategory_other_text = Object.values(
      //     value.subcategory_other
      //   )[0];
      // }
      delete value.category_other;
      // delete value.subcategory_other;
      if (this.isARequest) {
        value.is_request = 1;
      }
      if (this.Offer_id) {
        this.offersService.editOffer(this.Offer_id, value).subscribe((newOfferRes: any) => {
          this.loading = false;
          if (newOfferRes.status === 200) {
            this.router.navigateByUrl('/offer/' + this.Offer_id, value);
          } else {
            this.submitted = false;
            this.loading = false;

            if (newOfferRes.errors) {
              this.errorMsg = Object.values(newOfferRes.errors).join(' - ');
            } else {
              this.errorMsg = newOfferRes.message;
            }
            return this.notifications.error('Error', newOfferRes.message, {
              theClass: 'outline danger',
              timeOut: 6000,
              showProgressBar: false,
            });
          }
        });
      } else {
        this.offersService.newOffer(value).subscribe((newOfferRes: any) => {
          this.loading = false;
          if (newOfferRes.status === 200) {
            this.router.navigateByUrl('/offer/' + newOfferRes.data.offer_id);
          } else {
            this.submitted = false;
            this.loading = false;

            if (newOfferRes.errors) {
              this.errorMsg = Object.values(newOfferRes.errors).join(' - ');
            } else {
              this.errorMsg = newOfferRes.message;
            }
            return this.notifications.error('Error', newOfferRes.message, {
              theClass: 'outline danger',
              timeOut: 6000,
              showProgressBar: false,
            });
          }
        });
      }

    } else {
      return this.notifications.error('Error', 'Validation Error', {
        theClass: 'outline danger',
        timeOut: 6000,
        showProgressBar: false,
      });
    }
  }
  slelectAutoComplete(data) {
    if (this.lang == 'en') {
      this.newOfferForm.get('product_name_en').setValue(data.name_en);
    } else {
      this.newOfferForm.get('product_name_en').setValue(data.name_ar);
    }
  }
  closeAutoComplete() {
    this.openAutoComplete = false;
  }
}
