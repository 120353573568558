import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentLang;

  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private doc
  ) {
    this.translate.use(localStorage.getItem('lang') || 'en');
    this.translate.onLangChange.subscribe((lang) => {
      this.currentLang = lang.lang;
      this.setDir(lang.lang);
      localStorage.setItem('lang', lang.lang);
    });
  }

  setDir(lang) {
    this.doc.dir = lang === 'ar' ? 'rtl' : 'ltr';
  }
}
