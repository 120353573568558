import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public langEmitter: EventEmitter<GeneralService>;

  constructor(private http: HttpClient) {
    this.langEmitter = new EventEmitter();

  }

  upload(body) {
    return this.http.post(`${environment.api}upload`, body);
  }

  serialize(obj) {

    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + obj[p]);
      }
    }
    return str.join('&');
  }
  languageChange(lang) {
    this.langEmitter.emit(lang);
  }
}
