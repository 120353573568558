import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LookupsService } from 'src/app/services/lookups.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() status;
  @Output() filters = new EventEmitter();
  @Output() clear = new EventEmitter();

  sub: Subscription;

  cities = [];
  areas = [];
  categories = [];
  subCategories = [];
  statuses1 = [
    {
      name: 'Pending',
      id: 1,
      check: false,
    },
    {
      name: 'Approved',
      id: 2,
      check: false,
    },
    {
      name: 'Rejected',
      id: 3,
      check: false,
    },
  ];

  statuses2 = [
    {
      name: 'Open',
      id: 1,
      check: false,
    },
    {
      name: 'Closed',
      id: 2,
      check: false,
    },
  ];

  citySelected = [];
  areaSelected = [];
  categorySelected = [];
  subCategorySelected = [];
  statuesSelected = [];

  constructor(
    private lookupsService: LookupsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getFilters();
  }

  checkQuery() {

    this.sub = this.route.queryParams.subscribe((query) => {
      this.categorySelected = []
      this.subCategorySelected = []
      console.log(query)
      if (Object.keys(query).length) {
        // check if it is a child to get parent first then child
        if (query.parent_id) {
          const parent = this.categories.find(
            (cat) => cat.id == query.parent_id
          );
          if (query.categories) { // check param have subcategory
            var category = parent.children.find((child) => {
              return child.id == query.categories;
            });
          }

          this.categorySelected.push(parent);
          this.subCategories = parent.children;
          if (query.categories) { // check param have subcategory
            this.subCategorySelected.push(category);
          }
          this.categories = this.categories.map((cat) => {
            cat.check = cat.id === parent.id;
            return cat;
          });
          if (query.categories) { // check param have subcategory
            this.subCategories = this.subCategories.map((cat) => {
              cat.check = cat.id === category.id;
              return cat;
            });
          }
        } else {
          // check if it is not a child to get parent direct
          const category = this.categories.find((child) => {
            return child.id == query.categories;
          });
          this.categorySelected.push(category);
          this.categories = this.categories.map((cat) => {
            cat.check = cat.id === category.id;
            return cat;
          });
        }
        this.exportFilters();
      } else {
        this.exportFilters();
      }
    });
  }

  getFilters() {
    this.lookupsService.getAllLookups().subscribe((lookups: any) => {
      if (lookups.status === 200) {
        this.cities = lookups.data.cities
        this.cities.map((city) => {
          city.check = false;
          if (city.areasCount) {
            // city.areas.map((area) => {
            //   area.check = false;
            //   return area;
            // });
          }
          return city;
        });
        this.categories = lookups.data.sectors
        // this.categories = lookups.data.sectors.map((sector) => {
        //   sector.check = false;
        //   if (sector.children.length) {
        //     sector.children.map((child) => {
        //       child.check = false;
        //       return child;
        //     });
        //     return sector;
        //   }
        // });
        this.checkQuery();
      }
    });
  }

  checkInList(id, list, check) {
    this[list] = this[list].map((item) => {
      if (item.id === id) {
        item.check = check;
      }
      return item;
    });
  }

  select(e, item, type) {
    console.log(e, item, type)
    const checked = e.target.checked;
    if (checked) {
      this[`${type}Selected`].push(item);
    } else {
      this[`${type}Selected`] = this[`${type}Selected`].filter(
        (i) => i.id !== item.id
      );
    }
    if (type === 'city') {
      this.checkInList(item.id, 'cities', checked);
      if (
        this[`${type}Selected`].length > 1 ||
        !this[`${type}Selected`].length
      ) {
        this.areas = [];
        this.areaSelected = [];
      } else if (this[`${type}Selected`].length) {
        this.areas = this[`${type}Selected`][0].areas
          ? this[`${type}Selected`][0].areas
          : [];
      }
    }
    if (type === 'category') {
      this.checkInList(item.id, 'categories', checked);
      if (
        this[`${type}Selected`].length > 1 ||
        !this[`${type}Selected`].length
      ) {
        this.subCategories = [];
        this.subCategorySelected = [];
      } else if (this[`${type}Selected`].length) {
        this.subCategories = this[`${type}Selected`][0].children
          ? this[`${type}Selected`][0].children
          : [];
      }
    }
    this.exportFilters();
  }

  exportFilters(clear?) {
    const status = this.statuesSelected.map((obj) => obj.id).join();
    const city = this.citySelected.map((obj) => obj.id).join();
    const area = this.areaSelected.map((obj) => obj.id).join();
    const category = this.categorySelected.map((obj) => obj.id).join();
    const subcategories = this.subCategorySelected.map((obj) => obj.id).join();
    const filters = {
      status,
      city,
      area,
      category,
      subcategories,
    };
    if (clear) {
      this.clear.emit(filters);
      return;
    }
    console.log(status, this.statuesSelected);
    this.filters.emit(filters);
  }

  clearFilters() {
    this.cities = this.cities.map((city) => {
      city.check = false;
      if (city.areasCount) {
        // city.areas.map((area) => {
        //   area.check = false;
        //   return area;
        // });
        return city;
      }
    });
    this.categories = this.categories.map((sector: any) => {
      if (sector) {
        sector.check = false;
        if (sector.children) {
          sector.children.map((child) => {
            child.check = false;
            return child;
          });
          return sector;
        }
      }
    });
    this.statuesSelected = [];
    this.citySelected = [];
    this.areas = [];
    this.areaSelected = [];
    this.categorySelected = [];
    this.subCategories = [];
    this.subCategorySelected = [];
    this.exportFilters(true);
  }
}
