import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() lat;
  @Input() long;
  @Input() withoutSearch;
  @Input() mapControls: boolean = true;
  @Output() updateLatLang = new EventEmitter();
  latitude: any = 26.8206;
  longitude: any = 30.8025;
  latitude_mark: number;
  longitude_mark: number;
  zoom: number;
  address: string;
  private geoCoder;
  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnInit() {
    console.log('this.mapControls  ===>', this.mapControls)
    this.lat = parseFloat(this.lat)
    this.long = parseFloat(this.long)
    if (this.lat || this.long) {
      this.latitude = this.lat;
      this.longitude = this.long;
      this.latitude_mark = this.lat;
      this.longitude_mark = this.long;
    } else {
      this.loadPlacesAutocomplete();
    }
  }

  loadPlacesAutocomplete() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      // this.geoCoder = new google.maps.Geocoder();

      if (!this.withoutSearch) {
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            // verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.latitude_mark = place.geometry.location.lat();
            this.longitude_mark = place.geometry.location.lng();
            this.updateLangLat(this.latitude, this.longitude);
            this.zoom = 12;
          });
        });
      }
    });
  }
  getMyCurrentLocation() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      if (!this.withoutSearch) {
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            // verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.latitude_mark = place.geometry.location.lat();
            this.longitude_mark = place.geometry.location.lng();
            this.updateLangLat(this.latitude, this.longitude);
            this.zoom = 12;
          });
        });
      }
    });
  }
  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.latitude_mark = position.coords.latitude;
        this.longitude_mark = position.coords.longitude;
        this.updateLangLat(this.latitude, this.longitude);
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  updateLangLat(latitude, longitude) {
    this.updateLatLang.emit({ latitude, longitude });
  }

  markerDragEnd(event) {
    console.log(event)
    this.latitude_mark = event.coords.lat.toString();
    this.longitude_mark = event.coords.lng.toString();
    this.updateLangLat(this.latitude, this.longitude);
    // this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
          }
        } else {
        }
      }
    );
  }
}
