import { SearchPipe } from './../../utilities/pipes/search.pipe';
import { SharePopupComponent } from './../../components/offer/share-popup/share-popup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClient } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { OfferComponent } from 'src/app/components/offer/offer.component';
import { NgxBootstrapModule } from '../ngx-bootstrap/ngx-bootstrap.module';
import { FiltersComponent } from 'src/app/components/filters/filters.component';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { MapComponent } from 'src/app/components/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { RouterModule } from '@angular/router';
import { ImagePreloadDirective } from 'src/app/utilities/directives/default-img.directive';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { NoDataComponent } from 'src/app/components/no-data/no-data.component';
import { SearchComponent } from 'src/app/components/search/search.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    OfferComponent,
    FiltersComponent,
    ErrorComponent,
    MapComponent,
    ImagePreloadDirective,
    LoadingComponent,
    NoDataComponent,
    SearchComponent,
    SharePopupComponent,
    SearchPipe

  ],
  imports: [
    RouterModule,
    CommonModule,
    InfiniteScrollModule,

    TranslateModule.forChild(),
    FontAwesomeModule,
    NgxBootstrapModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAGue_n2PIwzqxAaxY0yzd5XgrVcF5hheI",
      libraries: ['places'],
    }),
    FormsModule,
  ],
  providers: [TranslateModule],
  exports: [
    CommonModule,
    InfiniteScrollModule,
    TranslateModule,
    OfferComponent,
    FiltersComponent,
    ErrorComponent,
    MapComponent,
    FontAwesomeModule,
    NgxBootstrapModule,
    AgmCoreModule,
    ImagePreloadDirective,
    LoadingComponent,
    NoDataComponent,
    SearchComponent,
    SearchPipe
  ],
})
export class SharedModule { }
