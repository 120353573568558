import { environment } from 'src/environments/environment';
import { GeneralService } from 'src/app/services/general.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faTelegram,
  faWhatsapp,
  faFacebookMessenger
} from '@fortawesome/free-brands-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { OffersService } from 'src/app/services/offers.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent implements OnInit, OnDestroy {

  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;
  faFacebookMessenger = faFacebookMessenger;
  faLinkedinIn = faLinkedinIn;
  faTelegram = faTelegram;
  private sub: any;
  offerDetails;

  offerId;

  fullDesc: BsModalRef;
  same_seller: any;
  similar: any;
  share_url: any;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private offersService: OffersService,
    private notifierService: NotifierService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private authService: AuthService,
    private generalService: GeneralService,
    private router: Router
  ) {
    generalService.langEmitter.subscribe((rep: any) => {
      if (rep) this.getOffer(this.offerId);



    });

  }
  getProfile(id?) {
    this.authService.getProfile(id).subscribe((profileRes: any) => {
      if (profileRes.status === 200) {
        localStorage.setItem('Profile', JSON.stringify(profileRes.data));
      }
    });
  }
  ngOnChanges(): void {

    this.checkOfferProfile()

  }

  ngOnInit() {
    if (this.authService.getStoredUserData()) {
      this.getProfile()
    }
    this.share_url = environment.share_link;
    this.checkOfferProfile()

  }
  checkOfferProfile() {
    this.route.queryParams.subscribe((params) => {
      let token = params['token'];
      if (token) {
        this.authService.setToken(token);
        this.authService.getProfile().subscribe((loginResponse: any) => {
          this.getOfferId();
          if (loginResponse.status === 200) {
            this.authService.successLogin(
              true,
              loginResponse.data.access_token,
              loginResponse.data
            );
          } else {
            this.authService.removeTokenAndProfile();
            this.notifierService.notify(
              'error', loginResponse.message);
          }
        });
      } else {
        this.getOfferId()
      }
    });
  }

  getOfferId() {
    this.sub = this.route.params.subscribe((params) => {
      if (params.offerId) {
        this.offerId = params.offerId;
        this.getOffer(+params.offerId);
      }
    });
  }
  getOffer(id) {
    this.offerDetails = {}
    this.similar = []
    this.same_seller = [];
    this.loading = true;
    this.offersService.getOffer(id).subscribe((offerRes: any) => {
      if (offerRes.status === 200) {
        this.offerDetails = this.fixDate(offerRes.data);
        this.same_seller = offerRes.same_seller;
        this.similar = offerRes.similar;
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
    });
  }

  fixDate(offer) {
    if (localStorage.getItem('lang') == 'en') {
      moment.locale('en');
    } else {
      moment.locale('ar');
    }
    offer.created_at = moment(offer.created_at).fromNow();
    return offer;
  }

  addToIntersets(id) {
    const user = this.authService.getStoredUserData()
    if (user) {
      this.offersService.interested(id).subscribe((interestedSub: any) => {
        if (interestedSub.status === 200) {
          this.notifierService.notify(
            'success',
            this.translate.instant(
              'OfferDetails.OfferAddedToIntersetsSuccessfully'
            ),
          );
          this.offerDetails.is_interested = true;
        } else if (interestedSub.status === 400) {
          this.notifierService.notify('error', interestedSub.message);
        } else {
          this.notifierService.notify('error', interestedSub.message);

        }
      });
    } else {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.loginFirst')
      );
    }
  }

  removeFromIntersets(id) {
    this.offersService.removeInterest(id).subscribe((removeRes: any) => {
      if (removeRes.status === 200) {
        this.notifierService.notify(
          'success',
          this.translate.instant(
            'OfferDetails.OfferRemovedFromIntersetsSuccessfully'
          )
        );
        this.offerDetails.is_interested = false;
      } else {
        this.notifierService.notify('error', removeRes.message);

      }
    });
  }

  applyForThisOffer(data, state) {
    const user = this.authService.getStoredUserData()
    if (user) {
      if (user.is_account_verified && !data.closed) {
        console.log('data.closed ==>', data.closed)
        if (!state) {
          this.offersService.apply(data.id).subscribe((applyRes: any) => {
            if (applyRes.status === 200) {
              this.notifierService.notify(
                'success',
                this.translate.instant('OfferDetails.ApplyOfferSuccessfully')
              );
              this.offerDetails.is_applied = true;
              this.router.navigate(['/offers/request/' + data.id])
            } else {
              this.notifierService.notify('error', applyRes.message);

            }
          });
        } else {
          this.notifierService.notify(
            'error',
            this.translate.instant('OfferDetails.YouAlreadyAppliedBefore')
          );
        }
      } else if (!user.is_account_verified) {
        this.notifierService.notify(
          'error',
          this.translate.instant('Login.errorApplyOffers')
        );
      } else if (data.closed) {
        this.notifierService.notify(
          'error',
          this.translate.instant('Login.errorStockError')
        );
      }
    } else {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.loginFirst')
      );
    }

  }

  fullDescription(template) {
    this.fullDesc = this.modalService.show(template);
  }

  ngOnDestroy() {
    if (this.sub instanceof Subscription) {
      this.sub.unsubscribe();
    }
  }

  shareWithFB(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('http://www.facebook.com/sharer.php?t=' + product.name + '&u='
      + this.share_url + product.id,
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithFBMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('fb://share' + '&u='
      + this.share_url + product.id + '&app_id=251056675749748',
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }

  shareWithMessenger(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('https://www.facebook.com/dialog/send?link='
      + this.share_url + product.id + '&app_id=251056675749748'
      + '&redirect_uri=' + this.share_url,
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);

  }
  shareWithMessengerMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('fb-messenger://share?link='
      + this.share_url + product.id + '&app_id=251056675749748',
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);

  }
  shareWithWhatsapp(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)
    window.open('https://web.whatsapp.com/send?text=' + encodeURI(this.share_url + product.id),
      'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithWhatsappMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)
    window.open('whatsapp://send?text=' + this.share_url + product.id,
      'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithTW(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)

    window.open('https://twitter.com/intent/tweet?text=' + '&url=' + this.share_url
      + product.id + '&p  [images][0]=' +
      product.image, 'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithTWMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)

    window.open('https://twitter.com/intent/tweet?' + this.share_url
      + product.id + '&p  [images][0]=' +
      product.image, 'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
}
