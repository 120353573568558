import { SharePopupComponent } from './../../components/offer/share-popup/share-popup.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OffersComponent } from 'src/app/pages/offers/offers.component';
import { OffersRoutingModule } from '../offers-routing/offers-routing.module';
import { OfferConversationComponent } from 'src/app/components/offer-conversation/offer-conversation.component';
import { OfferHeadDetailsComponent } from 'src/app/components/offer-head-details/offer-head-details.component';
import { OfferRequestInfoComponent } from 'src/app/components/offer-request-info/offer-request-info.component';
import { RequestsComponent } from 'src/app/pages/requests/requests.component';
import { OfferToolsComponent } from 'src/app/components/offer-tools/offer-tools.component';
import { SendMessageComponent } from 'src/app/components/send-message/send-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageComponent } from 'src/app/components/message/message.component';
import { SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({
  declarations: [
    OffersComponent,
    OfferConversationComponent,
    OfferHeadDetailsComponent,
    OfferRequestInfoComponent,
    RequestsComponent,
    OfferToolsComponent,
    SendMessageComponent,
    MessageComponent,

  ],
  imports: [
    SharedModule,
    OffersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot(),

  ],
  exports: [OffersComponent, SimpleNotificationsModule],
})
export class OffersModule { }
