import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ThreadService } from 'src/app/services/thread.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  msg_;
  threadInfo;
  lang: string = 'en';
  @Input() set message(value) {
    if (value) {
      this.msg_ = value;
    }
  }

  requestSampleForm: FormGroup;
  offerForm: FormGroup;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private threadService: ThreadService
  ) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang')
    if (this.msg.type === 3) {
      this.requestSampleForm = this.formBuilder.group({
        quantity: [this.msg ? this.msg.quantity : 0],
        unit_id: [this.msg ? (this.lang == 'en' ? this.msg.unit.name_en : this.msg.unit.name_ar) : '-'],
        city_id: [this.msg ? (this.lang == 'en' ? this.msg.city.name_en : this.msg.city.name_ar) : '-'],
        area_id: [this.msg ? (this.lang == 'en' ? this.msg.area.name_en : this.msg.area.name_ar) : '-'],
        address: [this.msg ? this.msg.address : ''],
      });
      // this.requestSampleForm.patchValue(this.msg);
    } else if (this.msg.type === 5) {
      this.offerForm = this.formBuilder.group({
        quantity: [this.msg ? this.msg.quantity : 0],
        unit_price: [this.msg ? this.msg.unit_price : 0],
        currency_id: [this.msg ? (this.lang == 'en' ? this.msg.currency.name_en : this.msg.currency.name_ar) : '-'],
        includes_vat: [this.msg ? (this.msg.includes_vat ? 'Yes' : 'No') : ''],
        includes_transportation: [this.msg ? (this.msg.includes_transportation ? 'Yes' : 'No') : ''],
        payment_terms: [this.msg ? this.msg.payment_terms : '-'],
        offer_validity_count: [this.msg ? this.msg.offer_validity_count : ''],
        offer_validity_period: [this.msg ? this.msg.offer_validity_period : ''],
        city_id: [this.msg ? (this.lang == 'en' ? this.msg.city.name_en : this.msg.city.name_ar) : '-'],
        area_id: [this.msg ? (this.lang == 'en' ? this.msg.area.name_en : this.msg.area.name_ar) : '-'],
        address: [this.msg ? this.msg.address : ''],
      });
      // this.offerForm.patchValue(this.msg);
    }
  }

  get msg() {
    return this.msg_;
  }

  get threadData() {
    return this.threadInfo;
  }

  get user() {
    return this.authService.getStoredUserData();
  }

  approve() {
    this.threadService.approve(this.msg.id).subscribe((approveRes: any) => {
      if (approveRes.status === 200) {
        this.msg_.approved = true;
      }
    });
  }

  reject() {
    this.threadService.reject(this.msg.id).subscribe((rejectRes: any) => {
      if (rejectRes.status === 200) {
        this.msg_.approved = false;
      }
    });
  }
}
