import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe', pure: false
})
export class SearchPipe implements PipeTransform {
  lang = localStorage.getItem('lang')
  transform(items: any, term): any {
    if (typeof term !== "undefined" && term != "") {
      // console.log(items)
      return items.filter(item => {
        if (this.lang == 'en') {
          return (
            item.name_en.toLowerCase().includes(term.toLowerCase())
          );
        } else {
          item.name_ar.toLowerCase().includes(term.toLowerCase())

        }

      });
    } else {

      return items;
    }
  }
}
