import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private http: HttpClient) { }

  newOffer(body) {
    return this.http.post(`${environment.api}offers`, body);
  }
  editOffer(id, body) {
    return this.http.post(`${environment.api}offers/update/${id}`, body);
  }

  getAllOffers(query) {
    return this.http.get(`${environment.api}offers${query ? '?' + query : ''}`);
  }
  exportOffers() {
    return this.http.get(`${environment.api}offers_export`);
  }

  getOffer(id) {
    return this.http.get(`${environment.api}offers/${id}`);
  }
  getAutocompleteLists(search) {
    let params = new HttpParams();
    params = search
    return this.http.get(`${environment.api}autocomplete_list`, { params: params });
  }

  interests() {
    return this.http.get(`${environment.api}interests`);
  }
  summaryOffers(id) {
    return this.http.get(`${environment.api}offers/${id}/summary_appliers`);
  }

  removeInterest(id) {
    return this.http.post(`${environment.api}offers/remove_interest/${id}`, {});
  }
  closeOffer(id) {
    return this.http.post(`${environment.api}offers/close/${id}`, {});
  }
  reportOffer(id, data) {
    return this.http.post(`${environment.api}threads/complain/${id}`, data);
  }

  interested(id) {
    return this.http.post(`${environment.api}offers/interested/${id}`, {});
  }

  apply(id) {
    return this.http.post(`${environment.api}offers/apply/${id}`, {});
  }

  offersHistory(query) {
    return this.http.get(
      `${environment.api}offers_history${query ? '?' + query : ''}`
    );
  }

  myOffers(query) {
    return this.http.get(
      `${environment.api}my_offers${query ? '?' + query : ''}`
    );
  }

  cancelApplication(id) {
    return this.http.post(
      `${environment.api}offers/cancel_application/${id}`,
      {}
    );
  }

  fixOffer(offer, nameC, descriptionC) {
    // fix image
    if (!offer.image) {
      offer.image = '../../../assets/img/default.png';
    }

    let img = new Image();
    img.src = offer.image
    // img.onload = () => {
    //   console.log('succse')
    // }
    img.onerror = () => {
      // console.log('failed')
      offer.image = '../../../assets/img/default.png';
    }

    // fix name
    offer.name = '';
    if (offer.product_name) {
      offer.name =
        offer.product_name.length > nameC
          ? offer.product_name.slice(0, nameC) + ' ...'
          : offer.product_name;
    }

    // fix description
    offer.description = '';
    if (offer.product_description) {
      offer.description =
        offer.product_description.length > descriptionC
          ? offer.product_description.slice(0, descriptionC) + ' ...'
          : offer.product_description;
    }

    return offer;
  }
}
