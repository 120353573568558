import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { OffersService } from 'src/app/services/offers.service';
import { GeneralService } from 'src/app/services/general.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
  isARequest;
  @Input() set requestWaste(value) {
    if (value) {
      this.isARequest = value;
    }
  }
  private filters_: any;
  @Input() set filters(value: string) {
    if (value) {
      this.filters_ = value;
      this.currentPage = 1;
      this.results = [];
      this.search(value, this.currentPage);
    }
  }
  results = [];
  currentPage = 1;
  loadMore = false;
  loading = true;

  constructor(
    private offersService: OffersService,
    private generalService: GeneralService,
    private authService: AuthService,
    private router: Router,
    private notifierService: NotifierService,
    private translate: TranslateService,

  ) {
    this.generalService.langEmitter
      .subscribe((rep: any) => {
        if (rep) {
          this.search(this.filters_, this.currentPage);
        }
      });
  }

  ngOnInit() {

  }

  get getFilters() {
    return this.filters_;
  }

  search(filters, page, loadMore?) {
    this.loading = true;
    if (this.isARequest) {
      filters.is_request = 1;
    } else {
      filters.is_request = 0;
    }
    filters = this.generalService.serialize({ ...filters, page });
    this.offersService.getAllOffers(filters).subscribe((offersRes: any) => {
      if (offersRes.status === 200) {
        if (offersRes.data.length) {
          // if (loadMore) {
          //   this.results.push(offersRes.data);
          // } else {
          // this.results = offersRes.data;
          // }

          if (offersRes.page === 1) {
            this.results = offersRes.data;
          } else if (offersRes.page > 1) {
            this.results.push(...offersRes.data);

          }
          this.loadMore =
            offersRes.page !== offersRes.total_pages;
        }
      }
      this.loading = false;
    });
  }

  loadMoreResults(loadMore) {
    this.search(this.getFilters, ++this.currentPage, loadMore);
  }

  routerLink(isARequest) {
    // let user =

    const user = this.authService.getStoredUserData()
    if (user) {
      if (user.is_account_verified) {
        if (isARequest) {
          this.router.navigate(['/createNewRequest']);
        } else {
          this.router.navigate(['/new-offer'])
        }
      } else {
        this.notifierService.notify(
          'error',
          this.translate.instant('Login.errorCreateOffers')
        );
      }
    } else {
      // this.router.navigate([''],{ queryParams: { returnUrl: '/account/orders' }})
      // this.router.navigate(['/auth/login']).then(() => {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.loginFirst'),
      );
      // })
    }
  }
}
