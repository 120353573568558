import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  filters = '';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private notifierService: NotifierService,


  ) { }

  ngOnInit() {
    this.getProfileType()
  }

  getProfileType() {
    this.route.queryParams.subscribe((params) => {
      let token = params['token'];
      if (token) {
        this.authService.setToken(token);
        this.authService.getProfile().subscribe((loginResponse: any) => {
          if (loginResponse.status === 200) {
            this.authService.successLogin(
              true,
              loginResponse.data.access_token,
              loginResponse.data
            );
            this.router.navigate(['/home']);
          } else {
            this.authService.removeTokenAndProfile();
            this.router.navigate(['/']);
            this.notifierService.notify('error', loginResponse.message);
          }
        });
      } else if (this.authService.getStoredUserData()) {
        this.getProfile()
      }
    });
  }

  applyFilters(filters) {
    this.filters = filters;
  }

  getProfile(id?) {
    this.authService.getProfile(id).subscribe((profileRes: any) => {
      if (profileRes.status === 200) {
        localStorage.setItem('Profile', JSON.stringify(profileRes.data));
      }
    });
  }
}
