import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-terms-conditions-popup',
  templateUrl: './terms-conditions-popup.component.html',
  styleUrls: ['./terms-conditions-popup.component.scss']
})
export class TermsConditionsPopupComponent implements OnInit {
  @Output() closeModel = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  closeModelButton() {
    this.closeModel.emit(null)
  }
}
