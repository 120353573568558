import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-offer-gallery',
  templateUrl: './offer-gallery.component.html',
  styleUrls: ['./offer-gallery.component.scss'],
})
export class OfferGalleryComponent implements OnInit, OnChanges {
  @Input() image = '';
  @Input() images = [];
  selectedOne;

  constructor() { }
  ngOnChanges() {
    this.images = []
    if (this.image) {
      if (this.images == null) this.images = []
      this.images = [this.image].concat(this.images);
      this.selectedOne = this.image || this.images[0] || '';
    }
  }
  ngOnInit() {
    if (this.image) {
      if (this.images == null) this.images = []
      this.images = [this.image].concat(this.images);
      this.selectedOne = this.image || this.images[0] || '';
    }
    console.log('image 2=>', this.images)

  }
}
