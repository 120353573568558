import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-offer-details-section',
  templateUrl: './offer-details-section.component.html',
  styleUrls: ['./offer-details-section.component.scss'],
})
export class OfferDetailsSectionComponent implements OnInit {
  @Input() data;

  constructor() {}

  ngOnInit() {}
}
