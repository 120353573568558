import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ThreadService } from 'src/app/services/thread.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-offer-conversation',
  templateUrl: './offer-conversation.component.html',
  styleUrls: ['./offer-conversation.component.scss'],
})
export class OfferConversationComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  threadData;
  @Input() request;
  @Input() set thread(value) {
    this.threadData = value;
  }

  @ViewChild('scroll', { static: false }) private myScrollContainer: ElementRef;

  faChevronRight = faChevronRight;

  sub: any;
  offerId;
  selected;
  threadId;
  is_owner: boolean = false;
  messages = [];
  loading = true;

  constructor(
    private threadService: ThreadService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe((params) => {
      console.log('params  params', params)
      if (params.threadId && params.offerId) {
        this.is_owner = true
        console.log('this.is_owner ==> true', this.is_owner)
      } else {
        this.is_owner = false
        console.log('this.is_owner ==> false', this.is_owner)

      }
      this.loading = true;
      if (params.threadId) {
        this.threadId = +params.threadId;
        this.getThread(+this.threadData.id, true);
        this.scrollToBottom();
        // setInterval(() => {
        this.getThread(+this.threadData.id);
        // }, 60000);
      }

    });
    console.log('this.threadData', this.threadData)
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  get threadInfo() {
    return this.threadData;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  updateThread(msgId) {
    this.getThread(this.threadData.id);
  }

  getThread(id, first?) {
    this.loading = false;
    if (first) {
      this.messages = this.threadInfo.messages.reverse();
    } else {
      this.threadService.thread(id).subscribe((msgsRes: any) => {
        if (msgsRes.status === 200) {
          if (
            msgsRes.data.offer_owner.offer_owner !==
            this.authService.getStoredUserData().id
          ) {
            this.messages = msgsRes.data.messages.reverse();
            // this.scrollToBottom();
            // window.scrollTo(0, document.body.scrollHeight);

          }
        } else {
          // this.router.navigate(['/']);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.sub instanceof Subscription) {
      this.sub.unsubscribe();
    }
  }
}
