import { environment } from './../../../../environments/environment';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss']
})
export class SharePopupComponent implements OnInit {
  @Input('data') data;
  share_url: any;
  encodeUrlName: string;
  @Output('')
  @Output() closeEmit = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.share_url = environment.share_link;
    this.encodeUrlName = encodeURI(this.data.name);
  }

  closePopup(data) {
    this.closeEmit.emit(data)

  }

  shareWithFB(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('http://www.facebook.com/sharer.php?t=' + product.name + '&u='
      + this.share_url + product.id,
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithFBMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('fb://share' + '&u='
      + this.share_url + product.id + '&app_id=251056675749748',
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }

  shareWithMessenger(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('https://www.facebook.com/dialog/send?link='
      + this.share_url + product.id + '&app_id=251056675749748'
      + '&redirect_uri=' + this.share_url,
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);

  }
  shareWithMessengerMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    window.open('fb-messenger://share?link='
      + this.share_url + product.id + '&app_id=251056675749748',
      'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);

  }
  shareWithWhatsapp(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)
    window.open('https://web.whatsapp.com/send?text=' + encodeURI(this.share_url + product.id),
      'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithWhatsappMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)
    window.open('whatsapp://send?text=' + this.share_url + product.id,
      'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithTW(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)

    window.open('https://twitter.com/intent/tweet?text=' + '&url=' + this.share_url
      + product.id + '&p  [images][0]=' +
      product.image, 'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  shareWithTWMobile(product) {
    const left = (screen.width / 2) - (500 / 2);
    const top = (screen.height / 2) - (500 / 2);
    const encodeUrl = encodeURI(product.name)

    window.open('https://twitter.com/intent/tweet?' + this.share_url
      + product.id + '&p  [images][0]=' +
      product.image, 'sharer', 'top=' + top
      + ',left=' + left + ',toolbar=0,status=0,width=' + 500 + ',height=' + 500);
  }
  copyInputMessage(product) {

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = product;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
