import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { OffersService } from 'src/app/services/offers.service';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit {
  offer;
  @Input() set data(value) {
    this.offer = this.fixImageAndDateAndName(value);
  }
  faSpinner = faSpinner;
  loadingFavorite: boolean = false
  @Input() del;
  @Output() deleteOffer = new EventEmitter();
  faTimes = faTimes;
  sharePopup: BsModalRef;

  constructor(private offersService: OffersService, private notifierService: NotifierService,
    private translate: TranslateService, private authService: AuthService,
    private modalService: BsModalService,

  ) { }

  ngOnInit() {
    if (this.data) {
    }
    // id, image, product_name, quantity
  }

  get getOffer() {
    return this.offer;
  }

  fixImageAndDateAndName(offer) {
    // fix image
    if (offer.image) {
      let img = new Image();
      img.src = offer.image

      img.onerror = () => {
        console.log('failed')
        offer.image = '../../../assets/img/default.png';
      }
    } else {
      offer.image = '../../../assets/img/default.png';
    }



    // fix name
    offer.name =
      offer.product_name.length > 40
        ? offer.product_name.slice(0, 40) + ' ...'
        : offer.product_name;

    // fix date
    offer.created_at = moment(offer.created_at).fromNow();
    return offer;
  }

  delete(id) {
    this.offersService.removeInterest(id).subscribe((removeRes: any) => {
      if (removeRes.status === 200) {
        this.deleteOffer.emit(id);
      }
    });
  }
  addToIntersets(id) {
    const user = this.authService.getStoredUserData()
    if (user) {
      this.loadingFavorite = true;

      this.offersService.interested(id).subscribe((interestedSub: any) => {
        if (interestedSub.status === 200) {
          this.notifierService.notify(
            'success',
            this.translate.instant(
              'OfferDetails.OfferAddedToIntersetsSuccessfully'
            ),
          );
          this.getOffer.is_interested = true;
        } else if (interestedSub.status === 400) {
          this.notifierService.notify('error', interestedSub.message);
        } else {
          this.notifierService.notify('error', interestedSub.message);

        }
        setTimeout(() => {
          this.loadingFavorite = false;
        }, 500);
      });
    } else {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.loginFirst')
      );
    }
  }

  removeFromIntersets(id) {
    this.loadingFavorite = true;
    this.offersService.removeInterest(id).subscribe((removeRes: any) => {
      if (removeRes.status === 200) {
        this.notifierService.notify(
          'success',
          this.translate.instant(
            'OfferDetails.OfferRemovedFromIntersetsSuccessfully'
          )
        );
        this.getOffer.is_interested = false;
      } else {
        this.notifierService.notify('error', removeRes.message);

      }
      setTimeout(() => {
        this.loadingFavorite = false;
      }, 500);
    });
  }
  openPopupShare(templete: TemplateRef<any>, config = null) {
    this.sharePopup = this.modalService.show(templete, config);

  }

}
