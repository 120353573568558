import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { OffersService } from 'src/app/services/offers.service';
import {
  OnDestroy,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { ThreadService } from 'src/app/services/thread.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
})
export class OffersComponent implements OnInit, OnDestroy {
  sub: any;
  offerId;
  selected;
  threadId;
  threads = [];
  threadData: any;
  loadingThread;
  offerData: any = [];
  modalRef: BsModalRef;
  offerCloseId: any;
  offerReportId: any;
  reportForm: FormGroup;
  selectedreason: '';
  reasons = [
    { name: 'reson 1', id: 1 },
    { name: 'reson 2', id: 2 },
    { name: 'reson 3', id: 3 },
    { name: 'reson 4', id: 4 },
    { name: 'Other reson', id: 5 },
  ]
  constructor(
    private threadService: ThreadService,
    private route: ActivatedRoute,
    private router: Router,
    private offersService: OffersService,
    private modalService: BsModalService,
    private notifierService: NotifierService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,


  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (this.offerId !== +params.offerId) {
        this.offerId = +params.offerId;
        this.threadId = params.threadId;
        // this.offersService.getOffer(params.offerId)
        //   .subscribe((rep: any) => {
        //     if (rep.status === 200) {
        //       this.offerData = rep.data.offer;
        //     }
        //   });

        this.getThreads(+params.offerId);
      }
    });
    this.getForm()
  }

  getForm() {
    this.reportForm = this.formBuilder.group({
      'reason_id': ['', Validators.compose([Validators.required])],
      'text': ['',]
    });
  }
  getThreads(id) {
    this.loadingThread = true;
    this.threadService.myOfferThreads(id).subscribe((threadsRes: any) => {
      if (threadsRes.status === 200) {
        this.threads = threadsRes.data;
        this.offerData = threadsRes.data.offer;

        if (this.threads.length) {
          this.select(this.threadId ? +this.threadId : +this.threads[0].id);
        } else {
          // this.redirectToHome();
        }
      } else {
        // this.redirectToHome();
      }
      setTimeout(() => {
        this.loadingThread = false;

      }, 100);
    });
  }

  getThread(id) {
    // this.loadingThread = true;
    this.threadService.thread(id).subscribe((threadRes: any) => {
      // this.loadingThread = false;
      if (threadRes.status === 200) {
        this.threadData = threadRes.data;
        this.offerData = threadRes.data.offer;


      } else {
        // this.router.navigate(['/']);
      }
    });
  }

  redirectToHome() {
    this.router.navigate(['']);
  }

  select(id) {
    if (this.selected !== id) {
      this.selected = id;
      this.router.navigateByUrl(
        '/offer/' + this.offerId + '/threads/' + this.selected
      );
      this.getThread(this.selected);
    }
  }

  ngOnDestroy() {
    if (this.sub instanceof Subscription) {
      this.sub.unsubscribe();
    }
  }
  clossOffer(id, template: TemplateRef<any>, config = null) {
    this.offerCloseId = id
    console.log('closeOffer ==>', id)
    this.modalRef = this.modalService.show(template, config);

    // const index = this.myOffers.findIndex(item => item.id == id)
    // if (index !== -1) {
    //     this.myOffers.splice(index, 1)
    // }
  }
  reportOffer(id, template: TemplateRef<any>, config = null) {
    if (this.threadId) {
      this.modalRef = this.modalService.show(template, config);
    } else {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.errorReport')
      );
    }
  }


  selectreason(data) {

    data = { ...data, label: data.name };
    this.selectedreason = data.name;
  }
  SubmitcloseOffer() {
    console.log(this.offerCloseId)
    if (this.offerCloseId) {
      this.offersService.closeOffer(this.offerCloseId)
        .subscribe((rep: any) => {
          if (rep.status === 200) {
            // const index = this.offerData.findIndex(item => item.id === this.offerCloseId)
            // if (index !== -1) {
            this.offerData.closed = true;
            this.modalRef.hide();
            this.notifierService.notify('success', rep.message);
            // }
          } else {
            this.notifierService.notify('error', rep.message);
          }
        });
    }
  }

  SubmitReportThreadOffer() {

    if (this.reportForm.invalid) {
      this.reportForm.markAllAsTouched()
      return
    }
    const data = this.reportForm.value;
    if (this.threadId) {
      this.offersService.reportOffer(this.threadId, data)
        .subscribe((rep: any) => {
          if (rep.status === 200) {
            // const index = this.offerData.findIndex(item => item.id === this.offerCloseId)
            // if (index !== -1) {
            //   this.offerData[index].closed = true;
            this.modalRef.hide();
            // }
          }
        });
    }
  }

}
