import { GeneralService } from './../../services/general.service';
import { Component, OnInit } from '@angular/core';
import { LookupsService } from 'src/app/services/lookups.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  categories = [];
  loading = true;
  constructor(private lookupsService: LookupsService, private generalService: GeneralService) {
    generalService.langEmitter.subscribe((rep: any) => {
      if(rep){
        this.getSectors();
      }

    })
  }

  ngOnInit() {
    this.getSectors();
  }

  getSectors() {
    this.categories = []
    this.loading = true;
    this.lookupsService.getCompanySectors().subscribe((sectors: any) => {
      if (sectors.status === 200) {
        this.loading = false;
        this.categories = sectors.data;
      }
    });
  }
}
