import { Component, OnInit, Input } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit {
  faCheck = faCheck;
  steps = [];
  @Input() step;
  @Input() set stepsCount(count) {
    for (let index = 0; index < count; index++) {
      this.steps.push(index + 1);
    }
  }

  constructor() {}

  ngOnInit() {}
}
