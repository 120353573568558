import { Directive, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
/**
 * Add default image to any img tag if src is null or image still load
 */
export class ImagePreloadDirective {
  constructor(private http: HttpClient) {}
  @Input() src: string;
  @Input() default: any;

  /**
   * @description
   * Update src attribute with default image
   */
  updateUrl() {
    this.src = this.default;
  }
  load() {}
}
