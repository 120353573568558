import { OffersService } from 'src/app/services/offers.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-offer-head-details',
  templateUrl: './offer-head-details.component.html',
  styleUrls: ['./offer-head-details.component.scss'],
})
export class OfferHeadDetailsComponent implements OnInit {
  @Input() thread;
  @Input() threadId;
  @Input() offerData;
  @Input() offerId;
  @Input() owner: boolean = true;
  @Output() closeOfferEmit = new EventEmitter()
  @Output() reportOfferEmit = new EventEmitter()
  @Output() cancelOfferEmit = new EventEmitter()

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.offerData) {
      console.log('offerData ==>', this.offerData)

    }
    this.route.params.subscribe((params) => {
      console.log(params)

    });
  }

  closeOffer(id) {
    this.closeOfferEmit.emit(id)
  }

  reportOffer(id) {
    this.reportOfferEmit.emit(id)
  }
  cancelOffer(id) {
    this.cancelOfferEmit.emit(id)
  }
}
