import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Promise.all([this.getLang(), this.getToken()])).pipe(
      switchMap((val) => {
        const lang = val[0];
        const token = val[1];
        request = request.clone({
          headers: request.headers.set('Accept-Language', lang),
        });
        if (token) {
          request = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + token),
          });
        }
        return next.handle(request);
      })
    );
  }

  getLang() {
    return Promise.resolve().then(() => {
      return localStorage.getItem('lang') || 'en';
    });
  }

  getToken() {
    return Promise.resolve().then(() => {
      return localStorage.getItem('Token') || sessionStorage.getItem('Token');
    });
  }
}
