import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { LayoutComponent } from 'src/app/pages/layout/layout.component';
import { CategoriesComponent } from 'src/app/pages/categories/categories.component';
import { OfferDetailsComponent } from 'src/app/pages/offer-details/offer-details.component';
import { NewOfferComponent } from 'src/app/pages/new-offer/new-offer.component';
import { OffersComponent } from 'src/app/pages/offers/offers.component';
import { UserGuard } from 'src/app/utilities/guard/user.guard';
import { RequestWasteComponent } from 'src/app/pages/request-waste/request-waste.component';
import { NewRequestWasteComponent } from 'src/app/pages/new-request-waste/new-request-waste.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'requestWaste',
        component: RequestWasteComponent,
      },
      {
        path: 'categories',
        component: CategoriesComponent,
      },
      {
        path: 'offer/:offerId',
        component: OfferDetailsComponent,
      },
      {
        path: 'offer/:offerId/threads',
        component: OffersComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'offer/:offerId/threads/:threadId',
        component: OffersComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'profile/:profileId',
        loadChildren: () =>
          import('../profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [UserGuard],
      },
      {
        path: 'new-offer',
        component: NewOfferComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'edit-offer/:offerId',
        component: NewOfferComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'duplicate-offer/:offerIdDuplicate',
        component: NewOfferComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'createNewRequest',
        component: NewRequestWasteComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('../offers/offers.module').then((m) => m.OffersModule),
        canActivate: [UserGuard],
      },
    ],
  },
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule { }
