import { GeneralService } from 'src/app/services/general.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  myProfileId;
  unread_count: any;
  page_size: any;
  notifications = [];
  page: number = 1;
  total_pages: number;
  lang: any;

  constructor(
    public translate: TranslateService,
    public authService: AuthService,
    private router: Router,
    private generalService: GeneralService
  ) {
    if (authService.getStoredUserData()) {
      this.myProfileId = authService.getStoredUserData().id;
    }
  }

  ngOnInit() {
    this.lang = localStorage.getItem('lang')
    this.getNotifications({ page: this.page })
  }

  getNotifications(data) {
    this.authService.getNotifications(data)
      .subscribe((rep: any) => {
        if (rep.status === 200) {
          this.setDataNotifications(rep, 'new');
        }
      })
  }

  public onScroll() {
    console.log(this.page)
    console.log(this.total_pages)
    // Update ending position to select more items from the array
    this.page += 1;
    if (this.page <= this.total_pages) {
      // update read for this notes
      this.authService.getNotifications({ page: this.page })
        .subscribe((rep: any) => {
          if (rep.status !== 200) {
            return false;
          } else if (rep.status == 200) {
            this.setDataNotifications(rep, 'push')
          }
        });
      // get new notifications
      // this.getNotifications({ page: this.page });
    }
  }
  setDataNotifications(rep, type) {
    console.log(type)
    if (type == 'new') {
      this.page_size = rep.page_size;
      this.page = rep.page;
      this.unread_count = rep.unread_count;
      this.notifications = rep.data;
      this.total_pages = rep.total_pages;
    } else {
      this.notifications.push(...rep.data);
      this.page_size = rep.page_size;
      this.page = rep.page;
      this.unread_count = rep.unread_count;
      this.total_pages = rep.total_pages;
    }
  }

  MakeNotificationRead(data) {
    this.authService.readNotification(data.id)
      .subscribe((rep: any) => {
        if (rep.status === 200) {
          this.getNotifications({ page: 1 })

        }
      });
    switch (data.type) {
      case 1: // owner has a new user apply for her offer(need offer_id and thread_id)
        this.router.navigate(['/offer/' + data.offer_id + '/threads/' + data.thread_id]);
        console.log('data.offer_id ', data.offer_id, ' data.thread_id', data.thread_id);
        console.log('data.type', data.type)
        break;
      case 2:
        console.log(data)
        if (data.my_offer) {
          this.router.navigate(['/offer/' + data.offer_id + '/threads/' + data.thread_id]);
        } else {
          this.router.navigate(['/offers/request/' + data.offer_id]);
        }
        break;
      case 3:
        break;
      case 4:
        this.router.navigate(['home'], {
          queryParams: {
            parent_id: data.category_id,
          },
        });
        break;
      case 5:
        // this.router.navigate(['/offers/request/' + data.thread_id]);
        break;
      case 6:
        this.router.navigate(['/offers/request/' + data.offer_id]);
        break;
      default:
    }


  }
  collapsMenu() {
    this.showMenu = !this.showMenu;
  }

  changeLang(lang) {
    this.lang = lang
    this.translate.use(lang);
    this.translate.defaultLang = lang;
    localStorage.setItem('lang', lang);
    // location.reload();
    this.generalService.languageChange(lang)
  }

  logout() {
    this.authService.logout().subscribe((logoutResponse) => {
      localStorage.removeItem('Token');
      localStorage.removeItem('Profile');
      sessionStorage.removeItem('Token');
      sessionStorage.removeItem('Profile');
      this.router.navigate(['/']);
    });
  }
}
