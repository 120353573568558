import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from 'src/app/pages/auth/auth.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { ForgetPasswordComponent } from 'src/app/components/forget-password/forget-password.component';
import { NewPasswordComponent } from 'src/app/components/new-password/new-password.component';
import { RegisterComponent } from 'src/app/components/register/register.component';
import { VerifyPhoneComponent } from 'src/app/components/verify-phone/verify-phone.component';
import { AuthGuard } from 'src/app/utilities/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'forgetPassword',
        component: ForgetPasswordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'verifyPhone',
        component: VerifyPhoneComponent,
      },
      {
        path: 'newPassword',
        component: NewPasswordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
