import { NotifierService } from 'angular-notifier';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  newPAsswordForm: FormGroup;
  submitted = false;
  mobile: string;
  errorMsg;
  otp: any;
  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.mobile = sessionStorage.getItem('mobile');
    this.otp = sessionStorage.getItem('otp');

    if (!this.mobile) {
      this.router.navigateByUrl('/auth/forgetPassword');
    }
    this.newPAsswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      {
        validator: this.MustMatch('password', 'confirmPassword'),
      }
    );
  }

  get f() {
    return this.newPAsswordForm.controls;
  }



  onSubmit() {
    this.submitted = true;
    const body = {
      mobile: this.mobile,
      otp: this.otp,
      password: this.newPAsswordForm.value.password,
    };
    console.log(body)
    console.log(this.otp)
    this.authService.resetPassword(body).subscribe((verifyResponse: any) => {
      if (verifyResponse.status === 200) {
        // this.authService.successLogin(
        //   true,
        //   verifyResponse.access_token,
        //   verifyResponse.data
        // );
        localStorage.removeItem('otp')
        localStorage.removeItem('mobile')
        this.router.navigateByUrl('/auth/login').then
        {
          this.notifierService.notify('success', verifyResponse.message);

        };

        // this.router.navigate(['/auth/newPassword']);
      } else {
        this.errorMsg = verifyResponse.message;
      }
      this.submitted = false
    });
  }

  resetForm() {
    this.submitted = false;
    this.newPAsswordForm.reset();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
