import { Component, OnInit, Input } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-offer-downloads-section',
  templateUrl: './offer-downloads-section.component.html',
  styleUrls: ['./offer-downloads-section.component.scss'],
})
export class OfferDownloadsSectionComponent implements OnInit {
  sheets;
  @Input() set data(sheets) {
    if (sheets) {
      this.sheets = sheets
      // this.sheets = this.fixLinks(sheets);
    }
  }
  faFile = faFile;

  constructor() { }

  ngOnInit() { }

  // fixLinks(sheets) {
  //   console.log(sheets)
  //   sheets = sheets.map((sheet) => {
  //     sheet = sheet.split('/uploads/')[1];
  //     return sheet;
  //   });
  //   return sheets;
  // }

  get getSheets() {
    return this.sheets;
  }

  downloadSheet(link) {
    window.open(link, '_blank');

  }
}
