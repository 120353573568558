import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ThreadService } from 'src/app/services/thread.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent implements OnInit, OnDestroy {
  @Output() update = new EventEmitter();
  msg = '';
  faPaperPlane = faPaperPlane;
  @Input('dataThreadId') dataThreadId
  sub: any;
  offerId;
  selected;
  threadId;

  constructor(
    private threadService: ThreadService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params.threadId) {
        this.threadId = +params.threadId;
      }
      if (params.offerId) {
        this.offerId = +params.offerId;
      }
    });
  }

  sendText() {
    this.send({ type: 1, text: this.msg });
  }

  send(body?) {
    this.threadService.newMsg(this.dataThreadId, body).subscribe((msgRes: any) => {
      if (msgRes.status === 200) {
        this.msg = '';
        this.update.emit(msgRes.data.message_id);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub instanceof Subscription) {
      this.sub.unsubscribe();
    }
  }
}
