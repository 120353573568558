import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  submitted = false;
  loading;
  errorMsg = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.forgetPasswordForm = this.formBuilder.group({
      mobile: ['', [Validators.required]],
    });
  }

  get f() {
    return this.forgetPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.errorMsg = '';
    if (this.forgetPasswordForm.valid) {
      this.loading = true;
      this.authService
        .ResendPassword(this.forgetPasswordForm.value)
        .subscribe((forgetResponse: any) => {
          this.loading = false;
          if (forgetResponse.status === 200) {
            sessionStorage.setItem(
              'mobile',
              this.forgetPasswordForm.value.mobile
            );
            this.router.navigateByUrl('/auth/verifyPhone');
          } else {
            this.errorMsg = forgetResponse.message;
          }
        });
    }
  }

  resetForm() {
    this.submitted = false;
    this.forgetPasswordForm.reset();
  }
}
