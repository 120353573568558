import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LookupsService } from 'src/app/services/lookups.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  modalRef: BsModalRef;
  registerForm: FormGroup;
  submitted = false;
  companyTypes = [];
  selectedTypes = [];
  companySectors = [];
  selectedSectors = [];
  errorMsg = '';
  loading;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private lookupsService: LookupsService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.getTypes();
    this.getSectors();
  }

  initForm() {
    this.registerForm = this.formBuilder.group(
      {
        contact_person: ['', [Validators.required]],
        company_name: ['', [Validators.required]],
        email: ['', [Validators.required,Validators.email]],
        mobile: ['', [Validators.required, Validators.pattern('(01)[0-9]{9}')]],
        company_types: [null, [Validators.required]],
        company_types_other: new FormGroup({}),
        sectors: [null, [Validators.required]],
        sectors_other: new FormGroup({}),
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
        terms: [true, [Validators.required]],
      },
      {
        validator: this.mustMatch('password', 'confirmPassword'),
      }
    );
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get regFormControls() {
    return this.registerForm.controls;
  }

  get otherTypesForm() {
    return this.regFormControls.company_types_other;
  }

  get otherSectorsForm() {
    return this.regFormControls.sectors_other;
  }

  // Get company types
  getTypes() {
    this.lookupsService.getCompanyTypes().subscribe((companyTypes: any) => {
      this.companyTypes = companyTypes.data;
    });
  }

  // Get sectors
  getSectors() {
    this.lookupsService.getCompanySectors().subscribe((companySectors: any) => {
      this.companySectors = companySectors.data;
    });
  }

  addOtherType(type) {
    const otherTypes = this.registerForm.get(
      'company_types_other'
    ) as FormGroup;
    otherTypes.addControl(type.label, new FormControl('', Validators.required));
  }

  removeOtherType(type) {
    const otherTypes = this.registerForm.get(
      'company_types_other'
    ) as FormGroup;
    otherTypes.removeControl(type.label);
  }

  addOtherSector(type) {
    const otherSectors = this.registerForm.get('sectors_other') as FormGroup;
    otherSectors.addControl(
      type.label,
      new FormControl('', Validators.required)
    );
  }

  removeOtherSector(sector) {
    const otherSectors = this.registerForm.get('sectors_other') as FormGroup;
    otherSectors.removeControl(sector.label);
  }

  resetForm() {
    this.submitted = false;
    this.registerForm.reset();
    this.errorMsg = '';
  }

  selectCompanyType(e, type) {
    type = { ...type, label: type.name };
    const check = e.target.checked;
    if (check) {
      this.selectedTypes.push(type);
      this.registerForm.patchValue({ company_types: this.selectedTypes });
      if (type.is_other) {
        this.addOtherType(type);
      }
    } else {
      this.selectedTypes = this.selectedTypes.filter(
        (obj) => obj.id !== type.id
      );
      this.registerForm.patchValue({ company_types: this.selectedTypes });
      if (type.is_other) {
        this.removeOtherType(type);
      }
    }
  }

  selectCompanySector(e, sector) {
    sector = { ...sector, label: sector.name };
    const check = e.target.checked;
    if (check) {
      this.selectedSectors.push(sector);
      this.registerForm.patchValue({ sectors: this.selectedSectors });
      if (sector.is_other) {
        this.addOtherSector(sector);
      }
    } else {
      this.selectedSectors = this.selectedSectors.filter(
        (obj) => obj.id !== sector.id
      );
      this.registerForm.patchValue({ sectors: this.selectedSectors });
      if (sector.is_other) {
        this.removeOtherSector(sector);
      }
    }
  }

  fixName(name) {
    return name.replace(/ /g, '').toLowerCase();
  }

  openTermsModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onSubmit() {
    this.submitted = true;
    this.errorMsg = '';
    if (this.registerForm.valid) {
      this.loading = true;
      const form = this.registerForm.value;
      const companyTypes = [];
      form.company_types.map((type) => {
        companyTypes.push({
          id: type.id,
          other_text: type.is_other
            ? form.company_types_other[type.name]
            : null,
        });
      });
      const sectors = [];
      form.sectors.map((sector) => {
        sectors.push({
          id: sector.id,
          other_text: sector.is_other ? form.sectors_other[sector.name] : null,
        });
      });
      const object = {
        contact_person: form.contact_person,
        company_name: form.company_name,
        mobile: form.mobile,
        password: form.password,
        email: form.email,
        company_types: companyTypes,
        sectors,
      };
      this.authService.register(object).subscribe((registerResponse: any) => {
        this.loading = false;
        if (registerResponse.status === 200) {
          sessionStorage.setItem('mobile', this.registerForm.value.mobile);
          sessionStorage.setItem('verifyPhoneLogin', 'true');
          this.router.navigateByUrl('/auth/verifyPhone');
          // this.authService.successLogin(
          //   true,
          //   registerResponse.access_token,
          //   registerResponse.data
          // );
        } else if (registerResponse.status === 422) {
          this.errorMsg = Object.values(registerResponse.errors).join(' / ');
        } else {
        }
      });
    } else {
    }
  }
}
