import { OffersService } from './../../services/offers.service';
import {
  OnDestroy,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { ThreadService } from 'src/app/services/thread.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit, OnDestroy {
  private sub: any;
  threadId;
  threadData;

  loading;
  offerData: any;
  modalRef: BsModalRef;
  offerCloseId: any;
  offerReportId: any;
  reportForm: FormGroup;

  selectedreason: '';
  reasons = [
    { name: 'reson 1', id: 1 },
    { name: 'reson 2', id: 2 },
    { name: 'reson 3', id: 3 },
    { name: 'reson 4', id: 4 },
    { name: 'Other reson', id: 5 },
  ]
  constructor(
    private threadService: ThreadService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private notifierService: NotifierService,
    private translate: TranslateService,
    private offersService: OffersService,
    private formBuilder: FormBuilder,
  ) {
    // this.router.events
    //   .pipe(filter(e => e instanceof NavigationStart))
    //   .subscribe((e: NavigationStart) => {
    //     const navigation = this.router.getCurrentNavigation();
    //     console.log("🚀 ~ file: requests.component.ts ~ line 59 ~ RequestsComponent ~ .subscribe ~ navigation", navigation.extras.state.notification)

    //   });
  }

  ngOnInit() {
    // this.window.scrollTo = 100
    this.sub = this.route.params.subscribe((params) => {
      console.log(params)
      if (params.threadId) {
        this.loading = true;
        this.threadId = +params.threadId;
        this.getThread(this.threadId);
      }

    });
    this.getForm()
  }

  getForm() {
    this.reportForm = this.formBuilder.group({
      'reason_id': ['', Validators.compose([Validators.required])],
      'text': ['',]
    });
  }

  getThread(id) {
    this.threadService.getThreed(id).subscribe((threadsRes: any) => {
      this.threadData = threadsRes.data;
      this.loading = false;
      if (threadsRes.status === 200) {
        this.threadData = threadsRes.data;
        this.offerData = threadsRes.data.offer;
        if (!this.offerData.is_applied) {
          this.router.navigate(['offer', this.threadId])
          this.notifierService.notify(
            'error',
            this.translate.instant('OfferDetails.errorNotApplied')
          );
        }
      } else {
        // this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub instanceof Subscription) {
      this.sub.unsubscribe();
    }
  }
  clossOffer(id, template: TemplateRef<any>, config = null) {
    this.offerCloseId = id
    console.log('closeOffer ==>', id)
    this.modalRef = this.modalService.show(template, config);

    // const index = this.myOffers.findIndex(item => item.id == id)
    // if (index !== -1) {
    //     this.myOffers.splice(index, 1)
    // }
  }
  reportOffer(id, template: TemplateRef<any>, config = null) {
    if (this.threadId) {
      this.modalRef = this.modalService.show(template, config);
    } else {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.errorReport')
      );
    }
  }

  selectreason(data) {

    data = { ...data, label: data.name };
    this.selectedreason = data.name;
  }
  SubmitcloseOffer() {
    console.log(this.offerCloseId)
    if (this.offerCloseId) {
      this.offersService.closeOffer(this.offerCloseId)
        .subscribe((rep: any) => {
          if (rep.status === 200) {
            // const index = this.offerData.findIndex(item => item.id === this.offerCloseId)
            // if (index !== -1) {
            this.offerData.closed = true;
            this.modalRef.hide();
            this.notifierService.notify('success', rep.message);
            // }
          } else {
            this.notifierService.notify('error', rep.message);
          }
        });
    }
  }

  cancelOffer(id, template: TemplateRef<any>, config = null) {
    this.offerCloseId = id
    if (this.threadId) {
      this.modalRef = this.modalService.show(template, config);
    } else {
      this.notifierService.notify(
        'error',
        this.translate.instant('Login.errorReport')
      );
    }
  }
  SubmitCancelApplication() {
    if (this.offerCloseId) {
      this.offersService.cancelApplication(this.offerCloseId)
        .subscribe((rep: any) => {
          if (rep.status === 200) {
            this.offerData.closed = true;
            this.modalRef.hide();
            this.router.navigate(['/offer', this.offerCloseId])
            this.notifierService.notify('success', rep.message);
            // }
          } else {
            this.notifierService.notify('error', rep.message);
          }
        });
    }
  }
  SubmitReportThreadOffer() {

    if (this.reportForm.invalid) {
      this.reportForm.markAllAsTouched()
      return
    }
    const data = this.reportForm.value;
    if (this.threadId) {
      this.offersService.reportOffer(this.threadId, data)
        .subscribe((rep: any) => {
          if (rep.status === 200) {
            // const index = this.offerData.findIndex(item => item.id === this.offerCloseId)
            // if (index !== -1) {
            //   this.offerData[index].closed = true;
            this.modalRef.hide();
            // }
          }
        });
    }
  }
}
