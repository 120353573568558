import { NgModule } from '@angular/core';
import { LayoutRoutingModule } from '../layout-routing/layout-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { SearchComponent } from 'src/app/components/search/search.component';
import { SearchResultComponent } from 'src/app/components/search-result/search-result.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { LayoutComponent } from 'src/app/pages/layout/layout.component';
import { CategoriesComponent } from 'src/app/pages/categories/categories.component';
import { CategoryComponent } from 'src/app/components/category/category.component';
import { OfferDetailsComponent } from 'src/app/pages/offer-details/offer-details.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OfferGalleryComponent } from 'src/app/components/offer-gallery/offer-gallery.component';
import { OfferDownloadsSectionComponent } from 'src/app/components/offer-downloads-section/offer-downloads-section.component';
import { OfferDetailsSectionComponent } from 'src/app/components/offer-details-section/offer-details-section.component';
import { NewOfferComponent } from 'src/app/pages/new-offer/new-offer.component';
import { StepComponent } from 'src/app/components/step/step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OffersModule } from '../offers/offers.module';
import { ngfModule, ngf } from 'angular-file';
import { RequestWasteComponent } from 'src/app/pages/request-waste/request-waste.component';
import { NewRequestWasteComponent } from 'src/app/pages/new-request-waste/new-request-waste.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SearchResultComponent,
    HomeComponent,
    LayoutComponent,
    CategoriesComponent,
    CategoryComponent,
    OfferDetailsComponent,
    OfferGalleryComponent,
    OfferDetailsSectionComponent,
    OfferDownloadsSectionComponent,
    NewOfferComponent,
    StepComponent,
    RequestWasteComponent,
    NewRequestWasteComponent,
  ],
  imports: [
    LayoutRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FontAwesomeModule,
    OffersModule,
    ngfModule,
  ],
})
export class LayoutModule {}
