import { TermsConditionsPopupComponent } from './../../components/register/terms-conditions-popup/terms-conditions-popup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from 'src/app/pages/auth/auth.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { AuthRoutingModule } from '../auth-routing/auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgetPasswordComponent } from 'src/app/components/forget-password/forget-password.component';
import { NewPasswordComponent } from 'src/app/components/new-password/new-password.component';
import { RegisterComponent } from 'src/app/components/register/register.component';
import { VerifyPhoneComponent } from 'src/app/components/verify-phone/verify-phone.component';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ForgetPasswordComponent,
    NewPasswordComponent,
    RegisterComponent,
    TermsConditionsPopupComponent,
    VerifyPhoneComponent,
  ],
  imports: [CommonModule, SharedModule, AuthRoutingModule, ReactiveFormsModule],
})
export class AuthModule {}
