import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) { }

  login(body) {
    return this.http.post(`${environment.api}login`, body);
  }

  register(body) {
    return this.http.post(`${environment.api}register`, body);
  }

  ResendPassword(body) {
    return this.http.post(`${environment.api}send_password_otp`, body);
  }

  resetPassword(body) {
    return this.http.post(`${environment.api}reset_password`, body);
  }

  verifyMobile(body) {
    return this.http.post(`${environment.api}verify_mobile`, body);
  }
  verifyMobileOtp(body) {
    return this.http.post(`${environment.api}verify_password_otp`, body);
  }

  resendOtp(body) {
    return this.http.post(`${environment.api}resend_code`, body);
  }

  getProfile(id?) {
    return this.http.get(`${environment.api}profile${id ? '/' + id : ''}`);
  }
  getTrustPoints(id) {
    return this.http.get(`${environment.api}user/trust_points/${id}`);
  }
  getNotifications(data) {
    return this.http.get(`${environment.api}notifications`, { params: data });
  }
  readNotification(id) {
    return this.http.get(`${environment.api}notification/read/${id}`);
  }

  logout() {
    return this.http.post(`${environment.api}logout`, {});
  }

  removeTokenAndProfile() {
    localStorage.removeItem('Token');
    localStorage.removeItem('Profile');
    sessionStorage.removeItem('Token');
    sessionStorage.removeItem('Profile');
  }
  successLogin(remember = true, token, profile) {
    if (remember) {
      localStorage.setItem('Token', token);
      localStorage.setItem('Profile', JSON.stringify(profile));
    } else {
      sessionStorage.setItem('Token', token);
      sessionStorage.setItem('Profile', JSON.stringify(profile));
    }
    this.router.navigateByUrl('/');
  }

  getStoredUserData() {
    return (
      JSON.parse(localStorage.getItem('Profile')) ||
      JSON.parse(sessionStorage.getItem('Profile'))
    );
  }

  setToken(token) {
    localStorage.setItem('Token', token);
  }

  getToken() {
    return (
      localStorage.getItem('Token') || sessionStorage.getItem('Token')
    )
  }
  changeMobile(body) {
    return this.http.post(`${environment.api}change_mobile`, body);
  }

  changeMobileVerify(body) {
    return this.http.post(`${environment.api}change_mobile/verify`, body);
  }

  changeMobileResend(body) {
    return this.http.post(`${environment.api}change_mobile/resend`, body);
  }

  changePassword(body) {
    return this.http.post(`${environment.api}change_password`, body);
  }

  updateProfile(body) {
    return this.http.post(`${environment.api}profile`, body);
  }
}
