import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-offer-request-info',
  templateUrl: './offer-request-info.component.html',
  styleUrls: ['./offer-request-info.component.scss'],
})
export class OfferRequestInfoComponent implements OnInit {
  @Input() thread;

  constructor() {}

  ngOnInit() {}
}
