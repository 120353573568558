import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  errorMsg = '';
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    sessionStorage.removeItem('verifyPhoneLogin')
    sessionStorage.removeItem('phone')
    sessionStorage.removeItem('otp')

    this.loginForm = this.formBuilder.group({
      mobile: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      remember: [true],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    console.log('===>remember', this.loginForm.value.remember)
    this.submitted = true;
    this.errorMsg = '';
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService
        .login(this.loginForm.value)
        .subscribe((loginResponse: any) => {
          this.loading = false;
          if (loginResponse.status === 200) {
            this.authService.successLogin(
              this.loginForm.value.remember,
              loginResponse.data.access_token,
              loginResponse.data
            );
          } else if (loginResponse.status === 403) {
            sessionStorage.setItem('mobile', this.loginForm.value.mobile);
            sessionStorage.setItem('verifyPhoneLogin', 'true');
            this.router.navigateByUrl('/auth/verifyPhone');
          } else {
            this.errorMsg = loginResponse.message;
          }
        });
    }
  }

  resetForm() {
    this.submitted = false;
    this.loginForm.reset();
  }
}
