import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ThreadService } from 'src/app/services/thread.service';
import { LookupsService } from 'src/app/services/lookups.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-offer-tools',
  templateUrl: './offer-tools.component.html',
  styleUrls: ['./offer-tools.component.scss'],
})
export class OfferToolsComponent implements OnInit {
  @Output() update = new EventEmitter();
  id;
  @Input() set threadId(value) {
    this.id = value;
  }
  technicalData: BsModalRef;
  technicalText = null;

  sampleRequest: BsModalRef;
  sampleForm: FormGroup;
  submitSampleForm = false;
  units = [];
  selectedUnit = null;
  cities = [];
  areas = [];
  selectedCity = null;
  selectedArea = null;

  visitRequest: BsModalRef;
  visitForm: FormGroup;

  proposal: BsModalRef;
  sendProposalForm: FormGroup;
  submitProposal = false;
  selectedQuantityType = null;
  currencies = [];
  selectedCurrency = null;
  selectedVat = null;
  yesOrNo = [
    {
      name: 'yes',
      value: true,
    },
    {
      name: 'no',
      value: false,
    },
  ];
  quantityTypes = [
    {
      name: 'All quantity',
      value: 1,
    },
    {
      name: 'Partial quantity',
      value: 2,
    },
  ];
  selectedTransportation = null;
  selectedPeriod = null;
  periods = [
    {
      name: 'Day(s)',
      value: 'day',
    },
    {
      name: 'Week(s)',
      value: 'week',
    },
    {
      name: 'Month(s)',
      value: 'month',
    },
    {
      name: 'Year(s)',
      value: 'year',
    },
  ];

  constructor(
    private modalService: BsModalService,
    private threadService: ThreadService,
    private lookupsService: LookupsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.getLookups();
  }

  getLookups() {
    this.lookupsService.getAllLookups().subscribe((lookupsResponse: any) => {
      if (lookupsResponse.status === 200) {
        this.cities = lookupsResponse.data.cities;
        // this.companySizes = lookupsResponse.data.company_sizes;
        // this.companyTypes = lookupsResponse.data.company_types;
        this.currencies = lookupsResponse.data.currencies;
        // this.equipmentAvailabilities =
        //   lookupsResponse.data.equipment_availabilities;
        // this.legalEntityTypes = lookupsResponse.data.legal_entity_types;
        // this.sectors = lookupsResponse.data.sectors;
        // this.supplyFrequencies = lookupsResponse.data.supply_frequencies;
        this.units = lookupsResponse.data.units;
      }
    });
  }

  // Request Technical Data Start
  requestTechnicalData(template) {
    this.technicalText = '';
    this.technicalData = this.modalService.show(template);
  }

  submitTechnicalData() {
    this.send({ type: 4, text: this.technicalText });
    this.technicalText = '';
    this.technicalData.hide();
  }
  // Request Technical Data End

  // Request Sample Start
  requestSample(template) {
    this.submitSampleForm = false;
    this.selectedUnit = '';
    this.selectedCity = '';
    this.selectedArea = '';
    this.sampleForm = this.formBuilder.group({
      type: [3],
      quantity: ['', Validators.required],
      unit_id: ['', Validators.required],
      unit_price: [''],
      city_id: ['', Validators.required],
      area_id: ['', Validators.required],
      latitude: [''],
      longitude: [''],
    });
    this.sampleRequest = this.modalService.show(template);
  }

  get sampleRequestForm() {
    return this.sampleForm.controls;
  }

  selectUnit(unit) {
    this.selectedUnit = unit;
    this.sampleForm.patchValue({ unit_id: unit.id });
  }

  selectCity(city, form) {
    this.selectedCity = city;
    this[form].patchValue({ city_id: city.id });
    this.selectedArea = '';
    this.areas = city.areas ? city.areas : [];
  }

  selectArea(area, form) {
    this.selectedArea = area;
    this[form].patchValue({ area_id: area.id });
  }

  updateLangLat(langLang, form) {
    this[form].patchValue({
      latitude: langLang.latitude,
      longitude: langLang.longitude,
    });
  }

  submitSampleRequest() {
    this.submitSampleForm = true;
    console.log(this.sampleForm.valid)
    console.log(this.sampleForm.value)
    if (this.sampleForm.valid) {
      this.send(this.sampleForm.value);
      this.sampleRequest.hide();
    }
  }
  // Request Sample End

  // Request Visit Start
  requestVisit(template) {
    this.visitRequest = this.modalService.show(template);
    this.visitForm = this.formBuilder.group({
      type: [2],
      date: [new Date(), Validators.required],
    });
  }

  get visitRequestForm() {
    return this.visitForm.controls;
  }

  submitVisitRequest() {
    if (this.visitForm.valid) {
      const value = this.fixDate(this.visitForm.value);
      this.send(value);
      this.visitRequest.hide();
    }
  }

  fixDate(data) {
    const date = new Date(data.date);
    data.date = moment(date).format('YYYY-MM-DD');
    return data;
  }
  // Request Visit End

  // Send Proposal Start
  sendProposal(template) {
    this.submitProposal = false;
    this.selectedUnit = '';
    this.selectedCity = '';
    this.selectedArea = '';
    this.selectedPeriod = '';
    this.proposal = this.modalService.show(template, {
      ignoreBackdropClick: true,
      keyboard: false,
      backdrop: 'static'
    });
    this.sendProposalForm = this.formBuilder.group({
      type: 5,
      quantity: ['', Validators.required],
      unit_price: ['', Validators.required],
      currency_id: ['', Validators.required],
      includes_vat: ['', Validators.required],
      includes_transportation: ['', Validators.required],
      payment_terms: ['', Validators.required],
      offer_validity_count: ['', Validators.required],
      offer_validity_period: ['', Validators.required],
      city_id: [''],
      area_id: [''],
      address: [''],
      latitude: [''],
      longitude: [''],
    });
  }

  get proposalForm() {
    return this.sendProposalForm.controls;
  }

  selectCurrency(currency) {
    this.selectedCurrency = currency.name;
    this.sendProposalForm.patchValue({ currency_id: currency.id });
  }

  selectVatValue(val) {
    this.selectedVat = val.name;
    this.sendProposalForm.patchValue({ includes_vat: val.value });
  }

  selectTransportationValue(val) {
    this.selectedTransportation = val.name;
    this.sendProposalForm.patchValue({ includes_transportation: val.value });
  }

  selectQuantityType(type) {
    this.selectedQuantityType = type;
    // TODO offer quantity and set it instate of static number
    this.sendProposalForm.patchValue({
      quantity: this.selectedQuantityType.value === 1 ? 1000 : '',
    });
  }

  selectPeriod(period) {
    this.selectedPeriod = period;
    this.sendProposalForm.patchValue({ offer_validity_period: period.value });
  }

  submitProposalOffer() {
    this.submitProposal = true;
    if (this.sendProposalForm.valid) {
      this.send(this.sendProposalForm.value);
      this.proposal.hide();
    }
  }
  // Send Proposal End

  send(body) {
    this.threadService.newMsg(this.id, body).subscribe((msgRes: any) => {
      if (msgRes.status === 200) {
        this.update.emit(msgRes.data.message_id);
      }
    });
  }
}
