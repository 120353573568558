import { NotifierService } from 'angular-notifier';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { faSpinner, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
})
export class VerifyPhoneComponent implements OnInit {
  faSpinner = faSpinner;
  verifyPhoneForm: FormGroup;
  submitted = false;
  mobile;
  errorMsg = '';
  resendLoading;
  verifyLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.mobile = sessionStorage.getItem('mobile');
    if (!this.mobile) {
      this.router.navigateByUrl('/auth/forgetPassword');
    }
    this.verifyPhoneForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });

    if (sessionStorage.getItem('verifyPhoneLogin')) {
      this.verifyLogin = true
    } else {
      this.verifyLogin = false
    }
  }

  get f() {
    return this.verifyPhoneForm.controls;
  }

  onSubmit() {
    if (this.verifyPhoneForm.invalid) {
      this.submitted = true;
      this.verifyPhoneForm.markAllAsTouched()
      return
    }

    if (this.verifyLogin) {
      const body = {
        mobile: this.mobile,
        code: this.verifyPhoneForm.value.otp,
      };
      this.authService.verifyMobile(body).subscribe((verifyResponse: any) => {
        if (verifyResponse.status === 200) {
          this.authService.successLogin(
            true,
            verifyResponse.data.access_token,
            verifyResponse.data
          );
          this.notifierService.notify('success', verifyResponse.message);
          // sessionStorage.setItem('otp', this.verifyPhoneForm.value.otp)
          // this.router.navigate(['/auth/newPassword']);
          sessionStorage.removeItem('verifyPhoneLogin')
        } else {
          this.errorMsg = verifyResponse.message;
        }
        this.submitted = false
      });
    } else {
      const body = {
        mobile: this.mobile,
        otp: this.verifyPhoneForm.value.otp,
      };
      this.authService.verifyMobileOtp(body).subscribe((verifyResponse: any) => {
        if (verifyResponse.status === 200) {
          // this.authService.successLogin(
          //   true,
          //   verifyResponse.access_token,
          //   verifyResponse.data
          // );
          sessionStorage.setItem('otp', this.verifyPhoneForm.value.otp)
          this.router.navigate(['/auth/newPassword']);
          this.notifierService.notify('success', verifyResponse.message);
        } else {
          this.errorMsg = verifyResponse.message;
        }
        this.submitted = false
      });
    }


  }

  reSend() {
    this.errorMsg = '';
    this.resendLoading = true;
    if (this.mobile) {
      if (this.verifyLogin) {

        this.authService
          .resendOtp({ mobile: this.mobile })
          .subscribe((forgetResponse: any) => {
            this.resendLoading = false;
            if (forgetResponse.status === 200) {
              this.notifierService.notify('success', forgetResponse.message);
            } else {
              this.errorMsg = forgetResponse.message;
              if (forgetResponse.retry_after) {
                this.errorMsg += `, ${this.translate.instant(
                  'VerifyPhone.RetryAfter'
                )} ${forgetResponse.retry_after} ${this.translate.instant(
                  'VerifyPhone.Second'
                )}`;
              }
            }
          });
      } else {

        this.authService
          .ResendPassword({ mobile: this.mobile })
          .subscribe((forgetResponse: any) => {
            this.resendLoading = false;
            if (forgetResponse.status === 200) {
              this.notifierService.notify('success', forgetResponse.message);
            } else {
              this.errorMsg = forgetResponse.message;
              if (forgetResponse.retry_after) {
                this.errorMsg += `, ${this.translate.instant(
                  'VerifyPhone.RetryAfter'
                )} ${forgetResponse.retry_after} ${this.translate.instant(
                  'VerifyPhone.Second'
                )}`;
              }
            }
          });
      }
    }
  }

  resetForm() {
    this.submitted = false;
    this.verifyPhoneForm.reset();
  }
}
