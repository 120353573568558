import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThreadService {
  constructor(private http: HttpClient) { }

  thread(id) {
    return this.http.get(`${environment.api}threads/${id}`);
  }

  myOfferThreads(id) {
    return this.http.get(`${environment.api}offers/threads/${id}`);
  }

  newMsg(threadId, body) {
    return this.http.post(`${environment.api}threads/${threadId}`, body);
  }

  getThreed(offer_Id) {
    return this.http.get(`${environment.api}applied_offer/thread/${offer_Id}`);
  }

  approve(msgId) {
    return this.http.post(`${environment.api}messages/approve/${msgId}`, {});
  }

  reject(msgId) {
    return this.http.post(`${environment.api}messages/reject/${msgId}`, {});
  }
}
