import { GeneralService } from 'src/app/services/general.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnChanges {
  category: any;
  @Input() data

  constructor(private router: Router) {

  }

  ngOnInit() {

  }
  ngOnChanges() {
    this.fixImage(this.data)
  }

  fixImage(category) {
    // fix image
    console.log('category ==>', category)
    let img = new Image();
    img.src = category.image

    img.onerror = () => {
      console.log('failed')
      category.image = '../../../assets/img/default.png';
    }

  }

  goToCategory(category) {
    this.router.navigate(['home'], {
      queryParams: {
        categories: category.id,
        parent_id: category.parent_id,
      },
    });
  }
}
